import { Button, IconButton, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import moment from "moment";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../utils/backend/firebase";
import { AiFillCloseSquare } from "react-icons/ai"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { sm: 460, xs: 320 },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function Message() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [payload, setPayload] = useState({
    name: "",
    email: "",
    comments: "",
    date: moment(new Date())
      .format("LL")
      .toString(),
    phone: "",
  });
  const usersCollectionRef = collection(db, "SupportConcern");
  const Support = async () => {
    await addDoc(usersCollectionRef, {
      name: payload.name,
      email: payload.email,
      comments: payload.comments,
      date: payload.date,
      phone: payload.phone,
    });
    
    db.collection('Notifications').add({
      CustomerName: payload.name ,
      CustomerEmail: payload.email,
      Desc: 'Clients Message!',
      Date: payload.date,
      Status: 'Unread',
      Title: 'New Message!'
  }).catch((error) => {
      console.log(error);
  });

    console.log(" Naks Pasok");
    setPayload({
      name: "",
      email: "",
      comments: "",
      phone: "",
      date: "",
    });
  };

  const handleChange = (prop) => (event) => {
    setPayload({ ...payload, [prop]: event.target.value });
  };

  return (
    <>
    <Box sx={{paddingLeft: { md: 30, xs: 1 },
                  paddingRight: { md: 30, xs: 1 },
                  textAlign: "center",
                  margin: 5}}> <Typography variant="h5" fontWeight="bold"> Message Us for Inquiries!</Typography></Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: { md: "row", xs: "column" },
        paddingLeft: { md: 30, xs: 0 },
        paddingRight: { md: 30, xs: 0 },
      }}
    >

      <Box
        sx={{
          display: "flex",
          width: { md: "50%", xs: "90%" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 1,
        }}
      >
        <TextField
          label='Name'
          value={payload.name}
          onChange={handleChange("name")}
          variant='outlined'
          fullWidth
          sx={{ margin: 2 }} />
        <TextField
          label='Email'
          variant='outlined'
          value={payload.email}
          onChange={handleChange("email")}
          fullWidth
          sx={{ margin: 2 }} />
        <TextField
          label='Mobile Number'
          variant='outlined'
          value={payload.phone}
          onChange={handleChange("phone")}
          fullWidth
          sx={{ margin: 2 }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: { md: "50%", xs: "90%" },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 1,
          position: "relative",
        }}
      >
        <TextField
          label='Comments'
          multiline
          rows={6}
          fullWidth
          variant='outlined'
          sx={{ marginTop: 1.5, marginBottom: 9 }}
          value={payload.comments}
          onChange={handleChange("comments")} />
        <Button
          variant='contained'
          color='primary'
          onClick={handleOpen}
          sx={{
            width: 250,
            margin: 1,
            borderRadius: 20,
            fontSize: 24,
            textTransform: "none",
            position: "absolute",
            right: 5,
            bottom: 5,
          }}
        >
          Submit
        </Button>
        <Modal open={open}>
          <Box sx={style}>
            <IconButton onClick={handleClose} sx={{ float: "right" }}>
              <AiFillCloseSquare color='#591934' size={25} />
            </IconButton>
            <Typography>Thankyouuu</Typography>
            <Button onClick={Support} variant="contained" color="success">Proceed</Button>
            <Button onClick={handleClose} variant="contained" color="error"> Cancel</Button>
          </Box>
        </Modal>
      </Box>
    </Box></>
  );
}

export default Message;
