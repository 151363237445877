import React, { useEffect, useState } from 'react'
import { Box, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Divider } from '@mui/material'
import { CreditCard, GCash } from '../../../utils/assests/images/methods'
import PaymentProcedure from './PaymentProcedure';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../utils/backend/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Circle, Star } from '@mui/icons-material';

const styles = {
    paymentType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: { xs: 150, md: 250 },
        height: { xs: 90, md: 120 },
        border: '3px solid #591934',
        margin: 1,
        padding: 1,
        '&:hover': {
            backgroundColor: '#e2e2e2'
        }
    },
    isActiveType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: { xs: 150, md: 250 },
        height: { xs: 90, md: 120 },
        border: '3px solid #591934',
        margin: 1,
        padding: 1,
        backgroundColor: '#e2e2e2'
    },
    price: {
        fontSize: { xs: 15, md: 24 },
        fontWeight: 500,
    },
    paymentMethod: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: { xl: '50vw', lg: '50vw', md: '90vw', sm: '90vw', xs: '90%' },
        height: 120,
        padding: 1,
        border: '3px solid #591934',
        margin: 1,
        position: 'relative',
        '&:hover': {
            backgroundColor: '#e2e2e2'
        }
    },
    isActiveMethod: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: { xl: '50vw', lg: '50vw', md: '90vw', sm: '90vw', xs: '90%' },
        height: 120,
        padding: 1,
        border: '3px solid #591934',
        margin: 1,
        backgroundColor: '#e2e2e2',
        position: 'relative',
    },
}
export default function PaymentType({ packageName, checkIn, time, guest, price, reference }) {
    const auth = getAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [events, setEvents] = useState([]);
    const [pType, setPType] = useState('');

    const handleType = (event) => {
        setPType(event.target.value);
    };
    const [pMethod, setPMethod] = useState('');
    const [pMData, setPMData] = useState({})
    const handleData = ({ id, data }) => {
        setPMData({ id, data })
    }
    const [payment, setPayment] = useState([]);
    const handleMethod = (event) => {
        setPMethod(event.target.value);
    };
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            const uid = user.uid;
            onSnapshot(doc(db, 'ClientsProfile', uid), (snapshot) => {
                setUserInfo(snapshot.data());
            })
            db.collection('PaymentMethods').onSnapshot(snapshot => {
                setPayment(snapshot.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                })))
            })
            db.collection('EventsDetails').onSnapshot(snapshot => {
                setEvents(snapshot.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                })))
            })
        });
    }, []);

    console.log(pMData)

    console.log(packageName, checkIn, time, guest, price);

    return (
        <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                <FormControl>
                    <Typography sx={styles.price}>Please select your payment type</Typography>
                    <Divider
                        orientation='horizontal'
                        sx={{ border: '2px solid #000', width: '100%', backgroundColor: '#000' }}
                    />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Half Payment"
                        name="radio-buttons-group"
                        value={pType}
                        onChange={handleType}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={pType === "Half Payment" ? styles.isActiveType : styles.paymentType}>
                            <FormControlLabel value="Half Payment" control={<Radio />} label="Half Payment" />
                            <Typography sx={styles.price}>PHP {(price / 2)}</Typography>
                        </Box>
                        <Box sx={pType === "Full Payment" ? styles.isActiveType : styles.paymentType}>
                            <FormControlLabel value="Full Payment" control={<Radio />} label="Full Payment" />
                            <Typography sx={styles.price}>PHP {price}</Typography>
                        </Box>
                    </RadioGroup>

                    <Typography sx={styles.price}>Please select your payment type</Typography>
                    <Divider
                        orientation='horizontal'
                        sx={{ border: '2px solid #000', width: '100%', backgroundColor: '#000' }}
                    />
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="half"
                        name="radio-buttons-group"
                        value={pMethod}
                        onChange={handleMethod}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {payment.map(({ id, data }) => (
                            <Box key={id} sx={pMethod === data.MoPName ? styles.isActiveMethod : styles.paymentMethod} >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel value={data.MoPName} control={<Radio />} onClick={() => { handleData({ id, data }) }} disabled={data.Status === "Inactive"}/>
                                    <Typography sx={styles.price}>{data.MoPName}</Typography>
                                </Box>
                                <Typography fontSize={15}><Circle sx={{fontSize: 15}} color={data.Status === "Active" ? 'success' : 'error'}/>{data.Status}</Typography>
                                <img src={data.MoPLogo} width='120px' style={{ position: 'absolute', right: 10 }} alt='ModeOfPayment' />
                            </Box>)
                        )}
                    </RadioGroup>
                </FormControl>
                <PaymentProcedure
                    paymentMethod={pMethod}
                    paymentType={pType}
                    paymentData={pMData}
                    userInfo={userInfo}
                    packageName={packageName}
                    checkIn={checkIn}
                    time={time}
                    guest={guest}
                    price={price}
                    event={events}
                    reference={reference}
                />
            </Box>
        </Box>
    )
}
