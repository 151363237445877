import React, {useEffect, useState} from 'react'
import { Box, Button, Typography } from "@mui/material";
import { BG, BG2, BG3 } from "../../../utils/assests/images/image";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../utils/backend/firebase';

const styles = {
  sliderimg: {
    width: '100%',
    height: { xl: 800, lg: 700, md: 500, xs: 350 },
    objectFit: 'cover'
  },
  muiPaper: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
  },
  text: {
    fontSize: { xl: 56, lg: 56, md: 42, sm: 30, },
    color: '#fff',
  },
  feats: {
    position: 'absolute',
    top: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    display: 'block',
    border: '3px solid #fff',
    width: { xl: 900, lg: 900, md: 500, sm: 300, xs: 300 },
    padding: 1,
    zIndex: 1,
    '&:hover': {
      border: '3px solid #E3C57F',
      backgroundColor: '#591934'
    }
  }
}

export default function Cover() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cont, setCont] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "GeneralSettings"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);
      });
      setTimeout(() => {
        setCont(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);
  return (
    <>
        {cont.map((gal) => (
          <Box key={gal.id}>
      <Box sx={styles.muiPaper}>
        <Box sx={styles.feats}>
          <Typography sx={styles.text} >Mikaella's Resort and Events Place</Typography>
        </Box>
        <AliceCarousel autoPlay autoPlayInterval="3000" disableButtonsControls disableDotsControls infinite={true} animationType='fadeout' animationDuration='800'>
          <Box component='img' src={BG} sx={styles.sliderimg} alt="Phase1" />
          <Box component='img' src={BG2} sx={styles.sliderimg} alt="Phase2" />
          <Box component='img' src={BG3} sx={styles.sliderimg} alt="Function Hall" />
        </AliceCarousel>
      </Box>
      <Box sx={{
        backgroundColor: '#F9D371',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' },
        opacity: 0.6,
        padding: 2,
        '&:hover': {
          opacity: 0.9,
        }
      }}>
        <Box>
          <Typography sx={{
            marginLeft: { xl: 5, md: 3, xs: 2 },
            marginRight: { xl: 5, md: 3, xs: 2 },
            fontWeight: 700,
            fontSize: { xl: 48, lg: 40, md: 32, xs: 24 }
          }}>
            LOOKING FOR A VENUE?
            <br />
          </Typography>
          <Typography sx={{ marginLeft: { xl: 5, md: 3, xs: 2 }, marginRight: { xl: 5, md: 3, xs: 2 }, fontSize: { xl: 26, lg: 22, md: 18, xs: 14 } }}>
            Try Mikaella's Resort and Events Place for as low as <b style={{ color: '#591934' }}>PHP {gal.LowestVenue}</b>
          </Typography>
        </Box>
        <Button
          sx={{
            border: 1,
            borderRadius: "8vmax",
            marginLeft: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: '0px' },
            justifySelf: { xl: 'unset', lg: 'unset', md: 'unset', xs: 'center' },
            marginRight: { xl: 15, lg: 15, md: 5, sm: 5, xs: 0 },
            width: { lg: 280, sm: 180, xs: 150 },
            padding: { lg: 2, sm: .5, xs: 0 },
            alignSelf: 'center',
            fontWeight: 700,
            fontSize: { lg: 24, sm: 18, xs: 16 },
            bgcolor: '#fff',
            '&:hover': {
              backgroundColor: '#591934',
              color: '#FFFFFF'
            }
          }}
          onClick={() => { navigate('/events/wedding') }}
        >
          INQUIRE NOW
        </Button>
      </Box>
    </Box >
          ))}
          </>
    );
  }