import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import WebsiteView from './viewbooking/WebsiteView'

export default function ViewBooking() {
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.up(900));
    return (
        <Box>
            <Nav />
            <WebsiteView />
            <Footer />
        </Box>
    )
}
