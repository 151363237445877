import * as React from 'react'
import { Box } from "@mui/material";
import { cover } from "../../../utils/assests/images/image";
import "react-alice-carousel/lib/alice-carousel.css";

const styles = {
  sliderimg: {
    width: '100%',
    height: { xl: 500, lg: 400, sm: 300, xs: 200 },
    objectFit: 'cover'
  }
}

export default function Cover() {
  return (
    <Box component='img' src={cover} sx={styles.sliderimg} alt="Phase1" />
  )
}
