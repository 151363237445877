import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, Box, Typography, Divider, Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db, storage } from "../../../utils/backend/firebase";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";

const Input = styled("input")({
  display: "none",
});
export default function PaymentConfirmation({ paymentMethod, paymentType, userInfo, packageName, checkIn, time, guest, price, reference }) {

  const [user, setUser] = useState("")
  const [ev, setEv] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [payload, setPayload] = useState({
    date: moment(new Date())
      .format("LL")
      .toString(),
    paymentStatus: ''
  });
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  }
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  useEffect(() => {
    if (paymentType === 'Half Payment') {
      setPayload({ ...payload, paymentStatus: 'half' })
    } else if (paymentType === 'Full Payment') {
      setPayload({ ...payload, paymentStatus: 'paid' })
    } else {
      setPayload({ ...payload, paymentStatus: '' })
    }
  }, [paymentType])

  useEffect(() => {
    onAuthStateChanged(auth, (users) => {
      getDoc(doc(db, "ClientsProfile", users.uid)).then((docSnap) => {
        if (docSnap.exists) {
          setUser(docSnap.data());
        }
      });
    });
  })
  useEffect(() => {
    onAuthStateChanged(auth, (users) => {
      getDoc(doc(db, "EventsDetails", users.uid)).then((docSnap) => {
        if (userInfo.userID === users.EventsID) {
          setEv(docSnap.data());
        }
      });
    });
  })

  const handleUpload = () => {
    if (selectedImage === null) {
      alert("No Image Selected!");
    } else {
      const imageName = selectedImage.name;
      const uploadTask = storage.ref('proof/' + imageName).put(selectedImage);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress function...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          //Error Function...
          console.log(error);
          alert(error.message);
        },
        () => {
          //complete function..
          storage
            .ref("proof")
            .child(imageName)
            .getDownloadURL()
            .then(url => {
              //post image inside db...
              if (reference === 'Booking') {
                db.collection("SummaryBook").add({
                  bookID: userInfo.userID,
                  imageUrl: url,
                  CustomerName: userInfo.displayName,
                  Phone: userInfo.phoneNumber,
                  Email: userInfo.email,
                  Address: userInfo.address,
                  PackageName: packageName,
                  ArriveDate: checkIn,
                  DepartDate: time,
                  TotalPerson: guest,
                  Price: price,
                  Method: paymentMethod,
                  Type: paymentType,
                  paymentStatus: payload.paymentStatus,
                  status: "Active"
                }).then((data) => (
                  db.collection("AllBooks").add({
                    reference: 'Booking',
                    localID: data.id,
                    bookID: userInfo.userID,
                    imageUrl: url,
                    CustomerName: userInfo.displayName,
                    Phone: userInfo.phoneNumber,
                    Email: userInfo.email,
                    Address: userInfo.address,
                    PackageName: packageName,
                    ArriveDate: checkIn,
                    DepartDate: time,
                    TotalPerson: guest,
                    Price: price,
                    Method: paymentMethod,
                    Type: paymentType,
                    paymentStatus: payload.paymentStatus,
                    status: "Active"
                  })
                ))
              } else if (reference === 'Events') {
                db.collection("EventsDetails").add({
                  bookID: userInfo.userID,
                  imageUrl: url,
                  CustomerName: userInfo.displayName,
                  Phone: userInfo.phoneNumber,
                  Email: userInfo.email,
                  Address: userInfo.address,
                  PackageName: packageName,
                  ArriveDate: checkIn,
                  DepartDate: time,
                  TotalPerson: guest,
                  Price: price,
                  Method: paymentMethod,
                  Type: paymentType,
                  paymentStatus: payload.paymentStatus,
                  status: "Active"
                }).then((data) => (
                  db.collection("AllBooks").add({
                    reference: 'Events',
                    localID: data.id,
                    bookID: userInfo.userID,
                    imageUrl: url,
                    CustomerName: userInfo.displayName,
                    Phone: userInfo.phoneNumber,
                    Email: userInfo.email,
                    Address: userInfo.address,
                    PackageName: packageName,
                    ArriveDate: checkIn,
                    DepartDate: time,
                    TotalPerson: guest,
                    Price: price,
                    Method: paymentMethod,
                    Type: paymentType,
                    paymentStatus: payload.paymentStatus,
                    status: "Active"
                  })
                ))
              } else {
                console.log('no data');
              }

              db.collection('Notifications').add({
                CustomerName: userInfo.displayName,
                CustomerEmail: userInfo.email,
                Desc: 'New Book!',
                Date: payload.date,
                Status: 'Unread',
                Title: 'New Message!'
              }).catch((error) => {
                console.log(error);
              });

              console.log(userInfo.displayName, userInfo.phoneNumber, userInfo.email,
                packageName, checkIn, time, guest, price, paymentMethod, paymentType, userInfo.address);
              alert('Upload Success!, Click Finish');
              setProgress(0);
              setSelectedImage(null);
            }).catch((error) => {
              console.log(error);
            });
        }
      );

    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
    >
      <Typography variant='h5' color='initial' sx={{ margin: "10px" }}>
        Please attach payment proof.
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{ borderTop: "3px solid #000", width: "98%" }}
      />
      <Box
        sx={{
          border: "1px dotted #591934",
          width: { xs: "300px", md: "420px" },
          height: "720px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <label htmlFor='icon-button-file'>
          <Input
            accept='image/*'
            id='icon-button-file'
            type='file'
            onChange={handleChange}
          />
          {!selectedImage ? (
            <IconButton
              color='primary'
              aria-label='upload picture'
              component='span'
            >
              <PhotoCamera fontSize='large' />
            </IconButton>
          ) : (
            <> <Button
              sx={{ float: 'right' }}
              variant='contained'
              color='error'
              onClick={removeSelectedImage}
            >
              x
            </Button>
              <Box
                component='img'
                src={URL.createObjectURL(selectedImage)}
                alt='Thumb'
                sx={{
                  width: { xs: "300px", md: "420px" },
                  height: "600px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              />

            </>
          )}
        </label>
      </Box>
      <Typography sx={{ margin: 2 }}>Click Upload before Finish</Typography>
      <Button variant='contained' color='primary' onClick={handleUpload}>
        Upload
      </Button>
    </Box>
  );
}
