import React from "react";
import { Box } from "@mui/material";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";


export default function EventsIndex() {
  return (
    <Box>
      <Nav />
      <Footer />
    </Box>
  );
}
