import React, { useState, useEffect } from 'react'
import {
    TextField, Typography,
    Button, Box, useMediaQuery, Checkbox, MenuItem
} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../../utils/backend/firebase';
import { doc, getDoc, setDoc, getDocs } from 'firebase/firestore';
import moment from 'moment';

const currencies = [
    {
        value: 'Day Tour',
        label: 'Day Tour',
    },
    {
        value: 'Overnight',
        label: 'Overnight',
    },
    {
        value: '22 Hours',
        label: '22 Hours',
    },

];

export default function Request() {
    const location = useLocation();
    const matchXS = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const [checkIn, setCheckIn] = useState(new Date());
    const [user, setUser] = useState({});
    useEffect(() => {
        onAuthStateChanged(auth, (users) => {
            getDoc(doc(db, "ClientsProfile", users.uid)).then((docSnap) => {
                if (docSnap.exists) {
                    setUser(docSnap.data());
                }
            });
        });
    }, []);
    console.log(user);
    const [cont, setCont] = useState([]);
    const [LowestVenue, setLowestVenue] = useState('');
    useEffect(() => {
        db.collection('GeneralSettings').onSnapshot(snapshot => {
            setCont(snapshot.docs.map(doc => ({
                data: doc.data(),
                id: doc.id
            })))
        })
    }, []);
    useEffect(() => {
        cont.map(({ id, data }) => {
            if (user) {
                setLowestVenue(data.LowestVenue);
            }
            return <div key={id}></div>
        })
    }, [cont, LowestVenue])
    const [payload, setPayload] = useState({
        fullName: '',
        address: '',
        contactNum: '',
        email: '',
        eventName: '',
        packageName: 'Function Hall',
        numberOfGuest: 1,
        time: '',
        requirements: '',
        checked: true
    });

    const handleIn = (newValue) => {
        setCheckIn(newValue);
    };

    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };

    const toPayment = () => {
        onAuthStateChanged(auth, (users) => {
            const userRef = doc(db, 'SampleBook', users.uid)
            setDoc(userRef, {
                EventsID: users.uid,
                FullName: payload.fullName,
                PhoneNumber: payload.contactNum,
                Email: payload.email,
                Address: payload.address,
                EventsName: payload.eventName,
                CheckIn: moment(new Date(checkIn)).format("LL").toString(),
                CheckOut: payload.time,
                TotalPerson: payload.numberOfGuest,
                Notes: payload.requirements
            });
        })
        navigate('/payment', {
            state:
            {
                packageName: payload.packageName + ' ' + location.state.happening,
                Fullname: payload.fullName,
                Email: payload.email,
                Phone: payload.contactNum,
                Address: payload.address,
                checkIn: checkIn,
                time: payload.time,
                totalPerson: payload.numberOfGuest,
                price: LowestVenue,
                Notes: payload.requirements,
                reference: 'Events',
            }
        })
    };
    return (
        <Box>
            <Box sx={{
                textAlign: "center",
                padding: { md: 3, sm: 1, xs: 1 },
                margin: { md: 2, sm: 1, xs: 1 },
            }}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }} color="error">Attention: </Typography>
                <Typography variant="h5" color="error">Before you book, please ask the availability of <br />the Date and Phase/Event that you want to book to avoid inconveniences for you.</Typography>
                <Link to="/contact" ><Typography variant="h6"> Click here to contact us.</Typography></Link>
            </Box>
            <Box sx={{
                textAlign: "center",
                padding: { md: 3, sm: 1, xs: 1 },
                margin: { md: 2, sm: 1, xs: 1 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <Typography variant='h4'> Please complete this form for your event requirements</Typography>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { md: 800, sm: 550, xs: '95%' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                }}>
                    <TextField
                        required
                        autoComplete='off'
                        label="Email"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        defaultValue="Email"
                        value={user.email}
                        onChange={handleChange('email')}
                        disabled
                    />
                    <TextField
                        required
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Full Name"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        defaultValue="Full Name"
                        value={user.displayName}
                        onChange={handleChange('fullName')}
                        disabled
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { md: 800, sm: 550, xs: '95%' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                }}>
                    <TextField
                        required
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Contact Number"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        defaultValue="Contact Number"
                        value={user.phoneNumber}
                        onChange={handleChange('contactNum')}
                        disabled
                    />
                    <TextField
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Event Name"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        value={payload.eventName}
                        onChange={handleChange('eventName')}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { md: 800, sm: 550, xs: '95%' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                }}>
                    <TextField
                        required
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Number of Guest"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        value={payload.numberOfGuest}
                        onChange={handleChange('numberOfGuest')}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {!matchXS ?
                            <MobileDatePicker
                                label="Check In"
                                inputFormat="MM/dd/yyyy"
                                minDate={new Date()}
                                value={checkIn}
                                onChange={handleIn}
                                renderInput={(params) => <TextField {...params} fullWidth sx={{ margin: 1 }} />}
                            />
                            :
                            <DesktopDatePicker
                                label="Check In"
                                inputFormat="MM/dd/yyyy"
                                minDate={new Date()}
                                value={checkIn}
                                onChange={handleIn}
                                renderInput={(params) => <TextField {...params} fullWidth sx={{ margin: 1 }} />}
                            />
                        }
                    </LocalizationProvider>
                    <TextField
                        required
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Time"
                        placeholder='Indicate if AM / PM'
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        value={payload.time}
                        onChange={handleChange('time')}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { md: 800, sm: 550, xs: '95%' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                }}>
                    <TextField
                        required
                        autoComplete='off'
                        inputProps={{ sx: { textTransform: 'capitalize' } }}
                        label="Address"
                        variant="outlined"
                        fullWidth
                        sx={{ margin: 1 }}
                        color='secondary'
                        defaultValue="Address"
                        value={user.address}
                        onChange={handleChange('address')}
                        disabled
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { md: 800, sm: 550, xs: '95%' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                }}>
                    <TextField
                        label="Notes"
                        multiline
                        rows={6}
                        fullWidth
                        variant='outlined'
                        sx={{ margin: 1 }}
                        value={payload.requirements}
                        onChange={handleChange('requirements')}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: { md: 800, sm: 550, xs: '95%' },
                }}>
                    <Checkbox defaultChecked value={payload.checked} onChange={handleChange('checked')} sx={{ verticalAlign: 'text-top' }} />
                    <Typography sx={{ margin: '10px 0px 10px 10px' }}>
                        I hereby agree and consent that Mikaella’s Resort and Events Place may collect, use, disclose and process my personal information set out in this form to help with my inquiry/reservation/request for quotation.
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: { md: 800, sm: 550, xs: '95%' },
                    position: 'relative',
                    marginBottom: { md: 5, sm: 6, xs: 8 },
                }}>
                    <Button
                        sx={{
                            border: 1,
                            borderColor: "#750550",
                            position: 'absolute',
                            right: 10,
                            width: { md: 300, sm: '50%', xs: '100%' },
                            fontWeight: 700,
                            fontSize: { lg: 24, sm: 18, xs: 16 },
                            bgcolor: '#fff',
                            '&:hover': {
                                backgroundColor: '#591934',
                                color: '#FFFFFF'
                            }
                        }}
                        onClick={toPayment}
                    >
                        SUBMIT QUOTATION
                    </Button>
                </Box>
            </Box >
        </Box>
    )
}
