//hooks
import React, { useState } from "react";
//.mui
import {
  Grid,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Button,
  Box,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ImFacebook2 } from "react-icons/im";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import { UserAuth } from "../../utils/backend/context/AuthContext";
import { FacebookAuthProvider, getAdditionalUserInfo, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { auth, db } from "../../utils/backend/firebase";
import { doc, setDoc } from "firebase/firestore";

const classes = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  myTextField: {
    width: { sm: 400, xs: 280 },
    margin: 1,
  },
  myTitle: {
    fontSize: { sm: "3rem", xs: "2rem" },
    fontFamily: "Varela Round",
    fontWeight: "bold",
  },
  myText: {
    fontSize: "1.5rem",
    opacity: "0.7",
    textAlign: "center",
    fontFamily: "Varela Round",
  },
  myLabels: {
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "Varela Round",
  },
};

export default function Login() {
  const FBprovider = new FacebookAuthProvider();
  const GLprovider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const { googleSignIn, facebookSignIn, submit } = UserAuth();
  const [payload, setPayload] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = useState("");
  const handleChange = (prop) => (event) => {
    setPayload({ ...payload, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPayload({
      ...payload,
      showPassword: !payload.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  //backend function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('')
    try {
      await submit(payload.email, payload.password)
      navigate('/')
    } catch (e) {
      setError(e.message)
      console.log(e.message)
    }
  };

  const handleGoogleSignIn = async () => {
    signInWithPopup(auth, GLprovider)
        .then((result) => {
            onAuthStateChanged(auth, (user = result.user) => {
                if (getAdditionalUserInfo(result).isNewUser) {
                    const userRef = doc(db, 'ClientsProfile', user.uid)
                    const fullName = user.displayName;
                    const parts = fullName.split(' ')
                  setDoc(userRef, {
                    userID: user.uid,
                    firstName: parts[0],
                    lastName: parts[1],
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL,
                    Birthday: '',
                    gender: '',
                    phoneNumber: '',
                    address: ''
                });
              } else {
                  // User is signed out
                  // ...
                  console.log('No user!');
              }
          });
      })
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
      });
};
  const handleFacebookSignIn = async () => {
    signInWithPopup(auth, FBprovider)
        .then((result) => {
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            onAuthStateChanged(auth, (user = result.user) => {
                if (getAdditionalUserInfo(result).isNewUser) {
                  const userRef = doc(db, 'ClientsProfile', user.uid);
                  const fullName = user.displayName;
                  const parts = fullName.split(' ')
                  setDoc(userRef, {
                    userID: user.uid, 
                    firstName: parts[0],
                    lastName: parts[1],
                    displayName: user.displayName,
                    email: user.email,
                    photoURL: user.photoURL + `?height=500&access_token=${accessToken}`,
                    Birthday: '',
                    gender: '',
                    phoneNumber: '',
                    address: '',
                 });
                 alert()
                    } else {
                        // User is signed out
                        // ...
                        console.log('No user!');
                    }
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    };


  return (
    <Grid container sx={classes.container}>
      {error && <Alert variant='danger'>{error}</Alert>}
      <Typography sx={classes.myTitle}>Sign In</Typography>
      <Box sx={{ flexDirection: "row" }}>
        <IconButton
          color='inherit'
          sx={{ m: 1, textTransform: "none" }}
          onClick={handleFacebookSignIn}
        >
          <ImFacebook2 style={{ color: "#5375e2" }} size='35px' />
        </IconButton>
        <IconButton
          color='inherit'
          sx={{ m: 1, textTransform: "none" }}
          onClick={handleGoogleSignIn}
        >
          <FcGoogle style={{ color: "#5375e2" }} size='35px' />
        </IconButton>
      </Box>
      <Typography sx={classes.myText}>or login</Typography>

      <TextField
        sx={classes.myTextField}
        label='Email'
        variant='outlined'
        color='secondary'
        value={payload.email}
        onChange={handleChange("email")}
      />

      <FormControl
        sx={classes.myTextField}
        variant='outlined'
        color='secondary'
      >
        <InputLabel htmlFor='outlined-adornment-Lpassword'>Password</InputLabel>
        <OutlinedInput
          id='outlined-adornment-Lpassword'
          type={payload.showPassword ? "text" : "password"}
          value={payload.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {payload.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label='Password'
        />
      </FormControl>

      <Button
        variant='contained'
        color='primary'
        sx={{ textTransform: "none", width: { sm: 400, xs: 280 }, mt: 2 }}
        onClick={handleSubmit}
      >
        <Typography
          sx={{
            fontFamily: "Varela Round",
            fontSize: { sm: "1.5rem", xs: "1rem" },
          }}
        >
          Login
        </Typography>
      </Button>
    </Grid>
  );
}
