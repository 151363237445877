import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { auth, db } from "../../../utils/backend/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
const styles = {
  data: {
    fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
    margin: 1,
  },
  editTextField: {
    margin: 1,
  },
  editButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
};
export default function ChangePass() {
  const [click, setClick] = useState(false);
  const [user, setUser] = useState("");
  const [payload, setpayload] = useState({
    nPass: "",
    cPass: "",
  });
const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (users) => {
      getDoc(doc(db, "ClientsProfile", users.uid)).then((docSnap) => {
        if (docSnap.exists) {
          setUser(docSnap.data());
        }
      });
    });
  }, )
      const update = async () => {
        onAuthStateChanged(auth, (users) => {
          if(user){
            if(!payload.nPass && !payload.cPass){
              window.confirm("Complete All Fields");
         }else if(payload.nPass === payload.cPass){
          updateDoc(doc(db, "ClientsProfile", users.uid), {
            password: payload.nPass,
          });
          window.confirm("Password Updated");
          navigate('/settings')
        }else{
            window.confirm("Not the same!");
        }
      }
        })
    };
    
  const handleChange = (prop) => (event) => {
    setpayload({ ...payload, [prop]: event.target.value });
  };
  const handleClick = () => {
    setClick(!click);
  };

  return user ? (
    <Box
      Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        width: "100%",
      }}
    >
      <Typography variant='h4' sx={styles.editTextField}>
        Change Password
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <TextField
          required
          id='outlined-required'
          label='New Password'
          autoComplete='off'
          fullWidth
          value={!click ? "" : payload.nPass}
          onChange={handleChange("nPass")}
          disabled={click === false}
          sx={styles.editTextField}
        />
        <TextField
          required
          id='outlined-required'
          label=' Confirm Password'
          autoComplete='off'
          fullWidth
          disabled={click === false}
          value={!click ? "" : payload.cPass}
          onChange={handleChange("cPass")}
          sx={styles.editTextField}
        />
      </Box>
      {click ? (
        <Button
          onClick={update}
          sx={styles.editButton}
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
        >
          Save
        </Button>
      ) : (
        <Button
        onClick={handleClick}
          sx={styles.editButton}
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
        >
          Update
        </Button>
      )}
    </Box>
  ) : null;
}
