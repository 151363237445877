import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../utils/backend/firebase";
function Intro() {
  const [isLoading, setIsLoading] = useState(false);
  const [cont, setCont] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "GeneralSettings"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);
      });
      setTimeout(() => {
        setCont(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
         <CircularProgress size='30vw' thickness={3} />
       </Box>
      ) : (
        <>
          {cont.map((gal) => (
            <Box key={gal.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingLeft: { md: 30, xs: 1 },
                  paddingRight: { md: 30, xs: 1 },
                  textAlign: "center",
                  margin: 5
                }}
              >
                <Typography
                  color='primary'
                  sx={{
                    fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
                    fontWeight: "bold",
                  }}
                >
                  CONTACT THE RESORT <br />
                </Typography>
                <Divider
                  orientation='horizontal'
                  sx={{
                    border: 2,
                    color: "#591934",
                    width: "50%",
                    justifySelf: "center",
                    margin: "auto",
                    borderRadius: 10,
                  }}
                />
                <Typography>Mikaella's Resort &#38; Events Place</Typography>
                <Typography>
                  {gal.RAddress}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { md: "row", xs: "column" },
                    textAlign: "center",
                  }}
                >
                  <Typography>Resort Mobile: </Typography>
                  <Typography>{gal.Phone}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { md: "row", xs: "column" },
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}>Facebook: </Typography>
                  <Box
                    component='a'
                    href={gal.FB}
                    target={"_blank"}
                    sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}
                  >
                    {gal.FB}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { md: "row", xs: "column" },
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}>Instagram: </Typography>
                  <Box
                    component='a'
                    href={gal.IG}
                    target={"_blank"}
                    sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}
                  >
                    {gal.IG}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { md: "row", xs: "column" },
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}> Messenger: </Typography>
                  <Box
                    component='a'
                    href='m.me/MikaellaResortAndEventsPlace'
                    target={"_blank"}
                    sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}
                  >
                    m.me/MikaellaResortAndEventsPlace
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { md: "row", xs: "column" },
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}>Email: </Typography>
                  <Box
                    component='a'
                    target={"_blank"}
                    href='mikaellasresortandeventsplace@gmail.com'
                    sx={{ fontSize: { xl: 24, lg: 24, md: 18, sm: 18, xs: 14 }, }}
                  >
                    mikaellasresortandeventsplace@gmail.com
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )
      }
    </>
  );
}

export default Intro;
