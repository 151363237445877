import React from 'react'
import { Box, Button, Modal, Portal, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AiFillCloseSquare } from "react-icons/ai"
//import { useNavigate } from 'react-router-dom';
import Login from './userPages/Login'
import Register from './userPages/Register'
import LoginAndReg from './LoginAndReg';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../utils/backend/context/AuthContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: 460, xs: 320 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

export default function QuotationRequestButton({ event }) {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [show, setShow] = React.useState(false);
    const container = React.useRef(null);

    const handleClick = () => {
        setShow(!show);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (user === null) {
            setOpen(true)
        } else {
            navigate('/events/request-quotation', {
                state:
                {
                    happening: event
                }
            })
        }
    };
    const handleClose = () => setOpen(false);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <Button
                sx={{
                    border: 1,
                    borderColor: "#750550",
                    fontWeight: 700,
                    fontSize: { lg: 24, sm: 18, xs: 16 },
                    margin: 2,
                    bgcolor: '#fff',
                    '&:hover': {
                        backgroundColor: '#591934',
                        color: '#FFFFFF'
                    }
                }}
                onClick={handleOpen}
            >
                BOOK NOW
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <LoginAndReg />
                </Box>
            </Modal>
        </Box>
    )
}
