//hooks
import React, { useState } from 'react'
//.mui
import {
    Grid, TextField, Typography, OutlinedInput,
    InputAdornment, IconButton, FormControl, InputLabel,
    Button, Box, useMediaQuery, FormLabel, RadioGroup,
    FormControlLabel, Radio, Alert
} from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//import { useNavigate } from 'react-router-dom';


//backend
import { db } from "../../utils/backend/firebase";
import {
    addDoc,
    collection,
    doc,
    setDoc,
} from "firebase/firestore";
import moment from 'moment';
import { UserAuth } from '../../utils/backend/context/AuthContext';
import { getAuth, createUserWithEmailAndPassword, getAdditionalUserInfo, onAuthStateChanged } from "firebase/auth";
const classes = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    myTextField: {
        width: { sm: 400, xs: 300 },
        margin: 1,
    },
    myTitle: {
        fontSize: { sm: '3rem', xs: '2rem' },
        fontFamily: 'Varela Round',
        fontWeight: 'bold'
    },
    myText: {
        marginBottom: 1,
        fontSize: '1rem',
        opacity: '0.7',
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
    myLabels: {
        fontSize: '1rem',
        paddingLeft: 1,
        textAlign: 'center',
        fontFamily: 'Varela Round',
    },
}

export default function Register() {
    const { createUser } = UserAuth();
    //const navigate = useNavigate();
    const matchXS = useMediaQuery('(min-width:600px)');
    const [birthDate, setBirthDate] = useState(new Date('2014-08-18'));
    const [errors, setErrors] = useState('')
    const [payload, setPayload] = useState({
        firstName: '',
        lastName: '',
        address: '',
        contactNum: '',
        email: '',
        password: '',
        confirmPass: '',
        gender: '',
        Birthday: birthDate,
        showPassword: false,
        showCPassword: false,
    });
    const auth = getAuth();
    const handleSubmit = async (e) => {
        createUserWithEmailAndPassword(auth, payload.email, payload.password)
            .then((userCredential) => {
                onAuthStateChanged(auth, (user) => {
                    if (getAdditionalUserInfo(userCredential).isNewUser) {
                        const usersCollectionRef = doc(db, "ClientsProfile", user.uid)
                        setDoc(usersCollectionRef, {
                            userID: user.uid,
                            displayName: payload.firstName + " " + payload.lastName,
                            address: payload.address,
                            phoneNumber: payload.contactNum,
                            email: payload.email,
                            Birthday: moment(new Date(birthDate)).format("LL").toString(),
                            gender: payload.gender,
                            password: payload.password
                        });
                    } else {
                        // User is signed out
                        // ...
                        console.log('No user!');
                    }
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    };


    const handleDate = (newValue) => {
        setBirthDate(newValue);
    };
    const handleChange = (prop) => (event) => {
        setPayload({ ...payload, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setPayload({ ...payload, showPassword: !payload.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowCPassword = () => {
        setPayload({ ...payload, showCPassword: !payload.showCPassword });
    };
    const handleMouseDownCPassword = (event) => {
        event.preventDefault();
    }
    return (
        <Grid container sx={classes.container}>
            <Typography sx={classes.myTitle}>Create an Account</Typography>
            {errors && (< Alert severity="error">
                {"Error - " + errors}
            </Alert>)}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: { sm: 'row', xs: 'column' }
            }}>
                <TextField
                    required
                    inputProps={{ sx: { textTransform: 'capitalize' } }}
                    label="First Name"
                    variant="outlined"
                    size='small'
                    sx={{ width: { sm: 192, xs: 300 }, marginRight: { sm: 1, xs: 0 }, my: 1 }}
                    color='secondary'
                    value={payload.firstName}
                    onChange={handleChange('firstName')}
                />
                <TextField
                    required
                    inputProps={{ sx: { textTransform: 'capitalize' } }}
                    label="Last Name"
                    variant="outlined"
                    size='small'
                    sx={{ width: { sm: 192, xs: 300 }, marginLeft: { sm: 1, xs: 0 }, my: 1 }}
                    color='secondary'
                    value={payload.lastName}
                    onChange={handleChange('lastName')}
                />
            </Box>
            <TextField
                required
                sx={classes.myTextField}
                label="Email"
                variant="outlined"
                size='small'
                color='secondary'
                value={payload.email}
                onChange={handleChange('email')}
            />

            <TextField
                required
                sx={classes.myTextField}
                label="Contact Number"
                variant="outlined"
                size='small'
                color='secondary'
                value={payload.contactNum}
                onChange={handleChange('contactNum')}
            />
            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary' >
                <InputLabel htmlFor="outlined-adornment-Rpassword">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Rpassword"
                    type={payload.showPassword ? 'text' : 'password'}
                    value={payload.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {payload.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
            <FormControl required sx={classes.myTextField} variant="outlined" size='small' color='secondary'>
                <InputLabel htmlFor="outlined-adornment-Cpassword">Confirm Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Cpassword"
                    type={payload.showCPassword ? 'text' : 'password'}
                    value={payload.confirmPass}
                    onChange={handleChange('confirmPass')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCPassword}
                                onMouseDown={handleMouseDownCPassword}
                                edge="end"
                            >
                                {payload.showCPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Confirm Password"
                />
            </FormControl>

            <TextField
                required
                sx={classes.myTextField}
                label="Address"
                variant="outlined"
                size='small'
                color='secondary'
                value={payload.address}
                onChange={handleChange('address')}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {!matchXS ?
                    <MobileDatePicker
                        label="Birthdate"
                        inputFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        minDate={new Date('1900-01-01')}
                        value={birthDate}
                        onChange={handleDate}
                        renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={classes.myTextField} />}
                    />
                    :
                    <DesktopDatePicker
                        label="Birthdate"
                        inputFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        minDate={new Date('1900-01-01')}
                        value={birthDate}
                        onChange={handleDate}
                        renderInput={(params) => <TextField {...params} size='small' color='secondary' sx={classes.myTextField} />}
                    />
                }
            </LocalizationProvider>

            <FormControl required component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    sx={{ width: { sm: 400, xs: 300 }, }}
                    value={payload.gender}
                    onChange={handleChange('gender')}>
                    <FormControlLabel value="male" control={<Radio color='secondary' />} label="Male" />
                    <FormControlLabel value="female" control={<Radio color='secondary' />} label="Female" sx={{ marginLeft: { sm: 20, xs: 10 } }} />
                </RadioGroup>
            </FormControl>

            <Button variant="contained" color='primary' sx={{ textTransform: 'none', width: { sm: 400, xs: 300 }, mt: 2 }} onClick={handleSubmit}>
                <Typography sx={{ fontFamily: 'Varela Round', fontSize: { sm: '1.5rem', xs: '1rem' } }}>Sign Up</Typography>
            </Button>
        </Grid>
    )
}
