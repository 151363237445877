import { Box, CircularProgress, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/backend/firebase";

function Map() {
  const [isLoading, setIsLoading] = useState(false);
  const [cont, setCont] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "GeneralSettings"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);
      });
      setTimeout(() => {
        setCont(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
       <CircularProgress size='30vw' thickness={3} />
     </Box>
      ) : (
        <>
          {cont.map((gal) => (
            <Box key={gal.id}>
              <Box
                sx={{
                  textAlign: "center",
                  margin: "auto",
                  fontSize: { xl: 28, lg: 24, md: 20, xs: 16 },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xl: 28, lg: 24, md: 20, xs: 16 },
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Office Hours:{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: { xl: 14, lg: 16, md: 13, xs: 10 } }}
                >
                  Monday to Sunday and Holidays, {gal.OHStart} to {gal.OHEnd}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xl: 25, lg: 20, md: 17, xs: 13 },
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  FOR GENERAL INQUIRIES &#38; FURTHER INFORMATION,{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xl: 25, lg: 20, md: 17, xs: 13 },
                    fontWeight: "bold",
                  }}
                >
                  PLEASE CONTACT US:
                </Typography>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3854.9484865345103!2d120.98115191477353!3d14.939963072675349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ab96f610ec05%3A0xc9472d24aa8405c8!2sMikaella%E2%80%99s%20Resort%20and%20Events%20Place!5e0!3m2!1sen!2sph!4v1649716197856!5m2!1sen!2sph'
                  width='90%'
                  height='450'
                  title='mikaellasmap'
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </Box>
            </Box>
          ))}
        </>
      )}
    </>
  );
}

export default Map;
