import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Nav from '../../../components/Nav'
import Footer from '../../../components/Footer'
import PaymentType from './PaymentType'
import { useLocation } from 'react-router-dom'
import moment from "moment";
export default function PaymentIndex() {
    const location = useLocation();
    const [bookData, setBookData] = useState({
        packageName: location.state.packageName,
        checkIn: moment(location.state.checkIn).format("LL").toString(),
        time: location.state.time,
        guest: location.state.totalPerson,
        price: location.state.price,
        reference: location.state.reference
    })
    return (
        <Box>
            <Nav />
            <PaymentType
                packageName={bookData.packageName}
                checkIn={bookData.checkIn}
                time={bookData.time}
                guest={bookData.guest}
                price={bookData.price}
                reference={bookData.reference}
            />
            <Footer />
        </Box>
    )
}
