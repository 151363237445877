import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { Navigate, Route, Routes } from 'react-router-dom'
import { theme } from './utils/theme'

import Home from './pages/Home/HomeIndex'
import RoomsAndCottages from './pages/RoomsAndCottages/RoomsAndCottagesIndex'
import Events from './pages/Events/EventsIndex'
import Promos from './pages/Promos/PromosIndex'
import Gallery from './pages/Shared Gallery/GalleryIndex'
import About from './pages/About Us/AboutIndex'
import Contact from './pages/Contact/ContactIndex'
import NotFound from './pages/NotFound'

//sub-menus
import Wedding from './pages/Events/EventsComp/Wedding'
import Celebrate from './pages/Events/EventsComp/Celebrate'
import Corporate from './pages/Events/EventsComp/Corporate'
import Request from './pages/Events/EventsComp/Request'
import Login from './userComp/userPages/Login'
import Register from './userComp/userPages/Register'
import ViewBooking from './userComp/userPages/ViewBooking'
import SettingsIndex from './userComp/userPages/SettingsIndex'
import PaymentIndex from './userComp/userPages/payment/PaymentIndex'
//backend
import { AuthContextProvider } from './utils/backend/context/AuthContext';
import Developers from './components/Developers'
import LoginAndReg from './userComp/LoginAndReg'


export default function App() {

  const themes = createTheme(theme);

  return (
    <ThemeProvider theme={themes}>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Navigate to={"/home"} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/rooms-and-cottages" element={<RoomsAndCottages />} />
          <Route path="/events" element={<Events />} >
            <Route path="wedding" element={<Wedding />} />
            <Route path="celebrate" element={<Celebrate />} />
            <Route path="corporate" element={<Corporate />} />
            <Route path="request-quotation" element={<Request />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/login-reg" element={<LoginAndReg />} />
          <Route path="/register" element={<Register />} />
          <Route path="/promos" element={<Promos />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/view-booking" element={<ViewBooking />} />
          <Route path="/settings" element={<SettingsIndex />} />
          <Route path="/payment" element={<PaymentIndex />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthContextProvider>
    </ThemeProvider>
  )
}
