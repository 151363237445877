import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Rating,
  Skeleton,
  Box
} from "@mui/material";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "../../../utils/backend/firebase";
const styles = {
  items: {
    padding: 2,
    display: "flex",
    width: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
    height: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
    position: "relative",
    backgroundColor: "#e2e2e2",
    justifyContent: "center",
  },
  feedback: {
    fontSize: 16,
    fontStyle: "Arial",
    margin: 2,
    color: "#000",
  },
  customerName: {
    fontSize: 20,
    position: "absolute",
    fontStyle: "italic",
    right: 10,
    bottom: 25,
  },
  date: {
    fontSize: 12,
    position: "absolute",
    right: 10,
    bottom: 10,
  },
};

export default function Feedback() {
  const [isLoading, setIsLoading] = useState(false);
  const [ratings, setRating] = useState([]);


  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const colRef = collection(db, "starrating");
      const querySnapshot = await getDocs(query(
        colRef,
        orderBy("rate_date", "desc"),
        limit(3))
      );
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);
      });
      setRating(dataUser);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
    fetchData();
  }, []);

  return (
    <Grid
      container
      spacing={5}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
      }}
    >
      {isLoading ? (
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
        }}>
          <Skeleton variant="rectangular"
            animation='wave'
            sx={{
              width: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              height: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              margin: 1,
              bgcolor: '#B497A4',
              borderRadius: 2
            }} />
          <Skeleton variant="rectangular"
            animation='wave'
            sx={{
              width: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              height: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              margin: 1,
              bgcolor: '#B497A4',
              borderRadius: 2
            }} />
          <Skeleton variant="rectangular"
            animation='wave'
            sx={{
              width: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              height: { xl: 300, lg: 300, md: 300, sm: 280, xs: 250 },
              margin: 1,
              bgcolor: '#B497A4',
              borderRadius: 2
            }} />
        </Box>
      ) : (
        <>
          {ratings.map((user) => {
            return (
              <Grid item key={user.id}>
                <Card sx={styles.items} elevation={5}>
                  <Typography sx={styles.feedback}>{user.comments}</Typography>
                  <Rating
                    readOnly
                    value={user.rate}
                    sx={{
                      fontSize: {
                        lg: "3rem",
                        xs: "2rem",
                        position: 'absolute',
                        bottom: 55,
                      },
                    }}
                  />
                  <Typography sx={styles.customerName} color='primary.main'>
                    - {user.name}
                  </Typography>
                  <Typography
                    sx={styles.date}
                    color='primary.main'
                    fontSize='5px'
                  >
                    {user.rate_date}
                  </Typography>
                </Card>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
}
