import React from 'react'
import { Box } from '@mui/material'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import Cover from './RoomsAndCottagesComp/Cover'
import Intro from './RoomsAndCottagesComp/Intro'
import Phase from './RoomsAndCottagesComp/Phase'

export default function RoomsAndCottagesIndex() {
    return (
        <Box>
            <Nav />
            <Cover />
            <Intro />
            <Phase />
            <Footer />
        </Box>
    )
}
