import React from 'react'
import { Box, Portal, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AiFillCloseSquare } from "react-icons/ai"
//import { useNavigate } from 'react-router-dom';
import Login from './userPages/Login'
import Register from './userPages/Register'

export default function LoginAndReg() {
    const [show, setShow] = React.useState(false);
    const container = React.useRef(null);

    const handleClick = () => {
        setShow(!show);
    };
    return (
        <Box ref={container}>
            {show ? (
                <Portal container={container.current} >
                    <Box>
                        <Typography color='blue' onClick={handleClick} sx={{ cursor: 'pointer', textAlign: 'left', mt: 2, fontSize: 18 }}><ArrowBackIcon sx={{ verticalAlign: 'text-bottom' }} fontSize='small' /><u>Back to Login</u></Typography>
                        <Register />
                    </Box>
                </Portal>
            ) :
                <Box>
                    <Login />
                    <Typography color='blue' onClick={handleClick} sx={{ cursor: 'pointer', textAlign: 'center', mt: 2, fontSize: 18 }}><u>Create an Account</u></Typography>
                </Box>
            }
        </Box>
    )
}
