import { Box, Divider, Typography, } from "@mui/material";
import React from "react";
import { Wed, Wed1, Wed2, Wed3, Wed4 } from "../../../utils/assests/images/image";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import QuotationRequestButton from "../../../userComp/QuotationRequestButton";

const styles = {
  sliderimg: {
    width: { xl: '50%', lg: '50%', md: '80%', sm: '80%', xs: '100%' },
    height: { xl: '50%', lg: '50%', md: '20%', sm: '20%', xs: '20%' },
    objectFit: 'cover',
    border: 2
  }
}
function Wedding() {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
      }}
    >
      <Typography
        color='primary'
        sx={{
          fontSize: { xl: 40, lg: 45, md: 40, xs: 30 },
          fontWeight: "bold",
        }}
      >
        YOUR DREAM WEDDING COMES TO LIFE!
        <br />
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#591934",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
        }}
      />
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        At Mikaella’s Resort and Events Place, we turn your dream wedding into a
        memorable moment. Our dedicated event managers and event specialist
        partners will create every detail of your event from personalized
        service to creating an magical moment to remember.
      </Typography>
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        We will make a unique and memorable celebration of unity in a
        magnificent setting suitable for your special event. You will definitely
        capture beautiful memories for a lifetime.
      </Typography>
      <QuotationRequestButton event={'- Wedding'} />
      <AliceCarousel autoPlay autoPlayInterval="3000" disableButtonsControls disableDotsControls infinite={true} animationType='fadeout' animationDuration='800'>
        <Box component='img' src={Wed} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Wed1} sx={styles.sliderimg} alt="Phase2" />
        <Box component='img' src={Wed2} sx={styles.sliderimg} alt="Function Hall" />
        <Box component='img' src={Wed3} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Wed4} sx={styles.sliderimg} alt="Phase1" />
      </AliceCarousel>
    </Box>
  );
}

export default Wedding;
