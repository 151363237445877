import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { GrInstagram, GrFacebook } from "react-icons/gr";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../utils/backend/firebase";

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.search, end: true });

  return (
    <div>
      <Link
        style={{
          textDecoration: "none",
          color: match ? "#F0D201" : "#FFFFFF",
          fontFamily: "VarelaRound",
          fontSize: match ? 22 : 18,
        }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

export default function Footer() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cont, setCont] = useState([]);
  const mounted = useRef(false);
  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "GeneralSettings"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);
      });
      setTimeout(() => {
        setCont(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);
  return (
    <>
      {cont.map((gal) => (
        <Box key={gal.id} ref={mounted}>
          <Box
            sx={{
              backgroundColor: "#591934",
              margin: "auto",
              textAlign: "center",
              padding: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                fontSize: "1%",
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                  flexDirection: "col",
                  color: "#fff",
                  marginTop: "30px",
                }}
              >
                <CustomLink to='/gallery' fontSize={14}>
                  Shared Gallery
                </CustomLink>
                <CustomLink to='/about-us' fontSize={14}>
                  About Us
                </CustomLink>
                <CustomLink to='/contact' fontSize={14}>
                  Contact
                </CustomLink>
                <CustomLink to='/developers' fontSize={14}>
                  Developers
                </CustomLink>
                <Button
                  variant='contained'
                  color='success'
                  sx={{
                    border: 1,
                    borderRadius: "5vmax",
                    color: "#fff",
                    fontSize: 14,
                    marginTop: 1,
                  }}
                  onClick={() => navigate('/rooms-and-cottages')}
                >
                  Book Now
                </Button>
              </Box>

              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  border: 2,
                  color: "#F0A500",
                  margin: 2,
                  borderRadius: "10px",
                }}
              />

              <Box
                sx={{
                  margin: "auto",
                  flexDirection: "col",
                  color: "#fff",
                  marginTop: "30px",
                }}
              >
                <Typography fontSize={24} sx={{ marginBottom: 2 }}>
                  FOLLOW US
                </Typography>
                <Box component='a' href={gal.FB} target={"_blank"}>
                  <GrFacebook
                    style={{
                      color: "#4D77FF",
                      fontSize: 32,
                      margin: "10px",
                    }}
                  />
                </Box>
                <Box component='a' href={gal.IG} target={"_blank"}>
                  <GrInstagram
                    style={{
                      color: "violet",
                      fontSize: 32,
                      margin: "10px",
                    }}
                  />
                </Box>
              </Box>

              <Divider
                orientation='vertical'
                flexItem
                sx={{
                  border: 2,
                  color: "#F0A500",
                  margin: 2,
                  borderRadius: "10px",
                }}
              />

              <Box
                sx={{
                  margin: "auto",
                  flexDirection: "col",
                  color: "#fff",
                  marginTop: "30px",
                }}
              >
                <CustomLink to='/contact' fontSize={14}>
                  How to Find us
                </CustomLink>
                <CustomLink to='/contact' fontSize={14}>
                  Lost & Found
                </CustomLink>
                <CustomLink to='/about-us' fontSize={14}>
                  Legal Information
                </CustomLink>
                <a href='https://www.freeprivacypolicy.com/live/414b8be3-1f16-40ba-9679-a17a34313c4b' target='_blank'
                  style={{
                    textDecoration: "none",
                    color: "#FFFFFF",
                    fontFamily: "VarelaRound",
                    fontSize: 18,
                  }}>
                  Personal Data Privacy & Security
                </a>
                <CustomLink to='/contact' fontSize={14}>
                  Notice
                </CustomLink>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: "1%",
              }}
            >
              <Typography
                fontSize={14}
                color='white'
                sx={{ marginTop: "30px" }}
              >
                &copy; 2022 by Mikaella's Resort and Events Place. All
                rights reserved.
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}
