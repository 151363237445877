import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  Link,
  useMediaQuery,
  TextField,
  Button,
  MenuItem
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  MobileDatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/lab";
import AliceCarousel from "react-alice-carousel";
import { BsSnow2, BsWifi } from "react-icons/bs";
import { GiFireBowl, GiShuttlecock, GiVolleyballBall } from "react-icons/gi";
import { useNavigate } from 'react-router-dom'
import moment from "moment";
import { auth, db } from "../../../utils/backend/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { addDoc, doc, setDoc } from "firebase/firestore";
import LoginAndReg from "../../../userComp/LoginAndReg";
import { UserAuth } from "../../../utils/backend/context/AuthContext";
const styles = {
  sliderimg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

const currencies = [
  {
    value: 'Day Tour',
    label: 'Day Tour',
  },
  {
    value: 'Overnight',
    label: 'Overnight',
  },
  {
    value: '22 Hours',
    label: '22 Hours',
  },

];

export default function PhasePreview({ data }) {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [mainIndex, setMainIndex] = useState(0);
  const [mainAnimation, setMainAnimation] = useState(false);
  const [thumbIndex, setThumbIndex] = useState(0);
  const [thumbAnimation, setThumbAnimation] = useState(false);

  const items = [
    <Box component='img' src={data.imageUrl} className='item' sx={styles.sliderimg} />,
    <Box component='img' src={data.imageUrl2} className='item' sx={styles.sliderimg} />,
    <Box component='img' src={data.imageUrl3} className='item' sx={styles.sliderimg} />,
    <Box component='img' src={data.imageUrl4} className='item' sx={styles.sliderimg} />,
    <Box component='img' src={data.imageUrl5} className='item' sx={styles.sliderimg} />,
  ];

  const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
    return items.map((item, i) => (
      <Box
        key={i}
        sx={{
          width: "90px",
          height: "60px",
          padding: "0px 5px",
        }}
        className='thumb'
        onClick={() => {
          setThumbIndex(i);
          setThumbAnimation(true);
        }}
      >
        {item}
      </Box>
    ));
  };
  const [thumbs] = useState(
    thumbItems(items, [setThumbIndex, setThumbAnimation])
  );

  const syncMainBeforeChange = (e) => {
    setMainAnimation(true);
  };

  const syncMainAfterChange = (e) => {
    setMainAnimation(false);

    if (e.type === "action") {
      setThumbIndex(e.item);
      setThumbAnimation(false);
    } else {
      setMainIndex(thumbIndex);
    }
  };

  const syncThumbs = (e) => {
    setThumbIndex(e.item);
    setThumbAnimation(false);

    if (!mainAnimation) {
      setMainIndex(e.item);
    }
  };
  // let roomContent = Array.from(data);
  const matchXS = useMediaQuery("(min-width:600px)");
  const [checkIn, setCheckIn] = useState(new Date());
  const [time, setTime] = useState('Day Tour');
  const [date, setDate] = useState("");
  const [currentUser, setCurrentUser] = useState('');
  const [book, setBook] = useState([]);
  const [payload, setpayload] = useState({
    adult: 1,
    child: 0,
  })
  const totalGuest = Number(payload.adult) + Number(payload.child);
  const handleIn = (newValue) => {
    setCheckIn(newValue);
  };
  const handleOut  = (event) => {
    setTime(event.target.value);
  };

  useEffect(() => {
    db.collection('SummaryBook').onSnapshot(snapshot => {
      setBook(snapshot.docs.map(doc => ({
        datas: doc.data(),
        id: doc.id
      })))
    })
  });
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setCurrentUser(authUser.uid)
      } else if (!authUser) {
        console.log('Log in!')
      }
    })

    return () => {
      unsubscribe();
    }
  }, []);


  const handleUpload = () => {
        onAuthStateChanged(auth, (user) => {
          const usersCollectionRef = doc(db, "SampleBook", user.uid)
          setDoc(usersCollectionRef, {
            PackageName: data.PackageName,
            RoomType: data.Beds,
            ArriveDate: moment(new Date(checkIn)).format("LL").toString(),
            DepartDate: time,
            TotalPerson: Number(payload.adult) + Number(payload.child),
            Adults: payload.adult,
            Childrens: payload.child,
            Price: data.Price,
          });
        })
        alert(" Successfully Booked");
        navigate('/payment', {
          state:
          {
            packageName: data.PackageName,
            checkIn: checkIn,
            time: time,
            totalPerson: totalGuest,
            price: data.Price,
            reference: 'Booking',
          }
  
    }) 
    
  }
  const handleChange = (prop) => (event) => {
    setpayload({ ...payload, [prop]: event.target.value });
  };
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              xl: "60%",
              lg: "60%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: { xl: 530, lg: 450, md: 450, sm: 350, xs: 180 },
              border: "3px solid #000",
              overflow: "hidden",
              marginBottom: 1,
            }}
          >
            <AliceCarousel
              activeIndex={mainIndex}
              animationType='fadeout'
              animationDuration={500}
              disableDotsControls
              disableButtonsControls
              infinite
              items={items}
              mouseTracking={!thumbAnimation}
              onSlideChange={syncMainBeforeChange}
              onSlideChanged={syncMainAfterChange}
              touchTracking={!thumbAnimation}
            />
          </Box>

          <Box className='thumbs'>
            <AliceCarousel
              activeIndex={thumbIndex}
              autoWidth
              autoHeight
              disableDotsControls
              disableButtonsControls
              items={thumbs}
              mouseTracking={false}
              onSlideChanged={syncThumbs}
              touchTracking={!mainAnimation}
            />
          </Box>
        </Box>
        {user ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: 3,
            padding: 2,
            margin: "0px auto 0px auto",
            height: { xl: 500, lg: 500, md: 480, sm: 480, xs: 480 },
            width: {
              xl: "30%",
              lg: "30%",
              md: "90%",
              sm: "90%",
              xs: "90%",
            },
            marginTop: { xl: 0, lg: 0, md: 3, sm: 3, xs: 3 },
            alignItems: "center",
          }}
        >
          
          <Typography
            sx={{
              color: "blue",
              fontSize: { xl: 24, lg: 24, md: 20, sm: 20, xs: 18 },
            }}
          >
            From
          </Typography>
          <Typography
            sx={{
              color: "blue",
              fontSize: { xl: 24, lg: 24, md: 20, sm: 20, xs: 18 },
            }}
          >
            PHP
            {data.Price}
          </Typography>
          <Typography sx={{ color: "blue", fontSize: 14 }}>
            per night
          </Typography>
          <Box
            sx={{
              display: "flex",

              width: "90%",
              flexDirection: "column",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {!matchXS ? (
                <MobileDatePicker
                  label='Check In'
                  inputFormat='MM/dd/yyyy'
                  minDate={new Date()}
                  value={checkIn}
                  onChange={handleIn}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      fullWidth
                      sx={{ marginTop: 2 }}
                    />
                  )}
                />
              ) : (
                <DesktopDatePicker
                  label='Check In'
                  inputFormat='MM/dd/yyyy'
                  minDate={new Date()}
                  value={checkIn}
                  onChange={handleIn}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      fullWidth
                      sx={{ marginTop: 2 }}
                    />
                  )}
                />
              )}
            </LocalizationProvider>

            <TextField
              id="outlined-select-time"
              select
              label="Time"
              value={time}
              onChange={handleOut}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              type='number'
              label='Adults'
              value={payload.adult}
              onChange={handleChange('adult')}
              fullWidth
              sx={{ marginTop: 2 }}
            />

            <TextField
              required
              value={payload.child}
              onChange={handleChange('child')}
              type='number'
              label='Children'
              fullWidth
              sx={{ marginTop: 2 }}
            />

            <Button
              variant='contained'
              sx={{
                marginTop: 2,
                width: 100,
                height: 100,
                borderRadius: "50%",
                alignSelf: "center",
              }}
              onClick={handleUpload}
            >
              {" "}
              BOOK NOW
            </Button>
          </Box>
        </Box>
        ) : ( <Box sx={{
          display: "flex",
          flexDirection: "column",
          border: 3,
          padding: 2,
          margin: "0px auto 0px auto",
          height: { xl: 500, lg: 500, md: 480, sm: 480, xs: 480 },
          width: {
            xl: "30%",
            lg: "30%",
            md: "90%",
            sm: "90%",
            xs: "90%",
          },
          marginTop: { xl: 0, lg: 0, md: 3, sm: 3, xs: 3 },
          alignItems: "center",
        }}><LoginAndReg/></Box>)}
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Box>
          <Typography variant='h5'>PROPERTIES</Typography>
          <Box component='span' sx={{ flexGrow: 0.5 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              marginTop: 1,
            }}
          >
            <Typography
              sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 } }}
            >
              Accomodation:
              {data.Accomodation}
            </Typography>
            <Typography
              sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 } }}
            >
              Beds:
              {data.Beds}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#000",
            width: "100%",
            margin: "30px 0px",
          }}
        />
        <Box>
          <Typography variant='h5'>DETAILS</Typography>
          <Box component='span' sx={{ flexGrow: 0.5 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              marginTop: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: { xl: 24, lg: 20, md: 18, xs: 16 },
                textAlign: "center",
              }}
            >
              {data.Caption}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#000",
            width: "100%",
            margin: "30px 0px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "column",
              sm: "column",
              xs: "column",
            },
          }}
        >
          <Typography variant='h5'>
            AMENITIES
            {data.Ammenities}
          </Typography>
          <Box component='span' sx={{ flexGrow: 0.5 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                display: data.AC ? "flex" : 'none',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BsSnow2 style={{ fontSize: 30, display: data.AC ? 'block' : 'none' }} />
              <Typography
                sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 }, display: data.AC ? 'block' : 'none' }}
              >
                A/C
              </Typography>
            </Box>
            <Box
              sx={{
                display: data.WiFi ? "flex" : 'none',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BsWifi style={{ fontSize: 30, display: data.WiFi ? 'block' : 'none' }} />
              <Typography
                sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 }, display: data.WiFi ? 'block' : 'none' }}
              >
                WiFi
              </Typography>
            </Box>
            <Box
              sx={{
                display: data.Grill ? "flex" : 'none',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <GiFireBowl style={{ fontSize: 30, display: data.Grill ? 'block' : 'none' }} />
              <Typography
                sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 }, display: data.Grill ? 'block' : 'none' }}
              >
                Grill
              </Typography>
            </Box>
            <Box
              sx={{
                display: data.Badminton ? "flex" : 'none',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <GiShuttlecock style={{ fontSize: 30, display: data.Badminton ? 'block' : 'none' }} />
              <Typography
                sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 }, display: data.Badminton ? 'block' : 'none' }}
              >
                Badminton Area
              </Typography>
            </Box>
            <Box
              sx={{
                display: data.Volleyball ? "flex" : 'none',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <GiVolleyballBall style={{ fontSize: 30, display: data.Volleyball ? 'block' : 'none' }} />
              <Typography
                sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16, display: data.Volleyball ? 'block' : 'none' } }}
              >
                Volleyball Area
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#000",
            width: "100%",
            margin: "30px 0px",
          }}
        />
        <Box>
          <Typography variant='h5'>CHECK IN &#38; OUT</Typography>
          <Box component='span' sx={{ flexGrow: 0.5 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              marginTop: 1,
            }}
          >
            <Typography
              sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 } }}
            >
              Check In: {data.HStart}
            </Typography>
            <Typography
              sx={{ fontSize: { xl: 24, lg: 20, md: 18, xs: 16 } }}
            >
              Check Out: {data.HEnd}
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#000",
            width: "100%",
            margin: "30px 0px",
          }}
        />
        <Box>
          <Typography variant='h5'>TERMS</Typography>
          <Box component='span' sx={{ flexGrow: 0.5 }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              textAlign: 'center',
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              marginTop: 1,
            }}
          >
            <Link href='#' sx={{ fontSize: 20 }}>
              READ OUR POLICIES
            </Link>
          </Box>
        </Box>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#000",
            width: "100%",
            margin: "30px 0px",
          }}
        />
      </Box>
    </Box>
  );
}
