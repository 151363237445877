import React, { useEffect } from 'react'
import { Stack, Box, Paper, Typography, Collapse } from '@mui/material';

import { BG2, Events2, Pool, Cot } from '../../../utils/assests/images/image'
import RoomsGallery from './offerGallery/RoomsGallery';
import PoolsGallery from './offerGallery/PoolsGallery';
import EventsGallery from './offerGallery/EventsGallery';
const styles = {
    muiPaper: {
        width: { xs: 300, sm: 250, md: 350, lg: 400 },
        height: { xs: 300, sm: 250, md: 350, lg: 400 },
        backgroundColor: 'rgba(89,25,52,0.7)',
        padding: 1,
        textAlign: 'center',
        position: 'relative',
        '&:hover': {
            backgroundColor: '#fff',
            opacity: 1
        }
    },
    paperImage: {
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        textAlign: 'center',
        opacity: 0.7,
        '&:hover': {
            opacity: 1
        }
    },
    text: {
        fontSize: { xl: 24, lg: 24, md: 16, sm: 16, },
        color: '#fff',
    },
    feats: {
        position: 'absolute',
        top: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        display: 'block',
        border: '3px solid #fff',
        width: { xl: 250, lg: 250, md: 200, sm: 180, xs: 150 },
        padding: 1,
        color: '#591934',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#591934',
            color: '#fff',
            border: '3px solid #fff',
        }
    }
}

export default function Offer() {
    const [checkedRooms, setCheckedRooms] = React.useState(false);
    const [checkedPools, setCheckedPools] = React.useState(false);
    const [checkedEvents, setCheckedEvents] = React.useState(false);

    useEffect(() => {
        setCheckedPools(false);
        setCheckedEvents(false);
        setCheckedRooms(true);
    }, [])

    const handleChangeRooms = () => {
        setCheckedPools(false);
        setCheckedEvents(false);
        setTimeout(() => {
            setCheckedRooms((prev) => !prev);
        }, 500);

    };

    const handleChangePools = () => {
        setCheckedRooms(false);
        setCheckedEvents(false);
        setTimeout(() => {
            setCheckedPools((prev) => !prev);
        }, 500);
    };

    const handleChangeEvents = () => {
        setCheckedPools(false);
        setCheckedRooms(false);
        setTimeout(() => {
            setCheckedEvents((prev) => !prev);
        }, 500);
    };
    return (
        <Box>
            <Typography sx={{ textAlign: 'center', fontSize: { xs: 24, sm: 32, md: 36 }, fontWeight: 600, padding: { xs: 2, sm: 3, md: 5 } }}>WHAT WE OFFER</Typography>
            <Box sx={{
                backgroundImage: `url(${BG2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}>
                <Box sx={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    padding: { xl: 5, xs: 2 }
                }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 2, sm: 4, md: 6 }}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 1, zIndex: 1 }}
                    >
                        <Paper sx={styles.muiPaper}>
                            <Box component='img' src={Cot} sx={styles.paperImage} />
                            <Box onClick={handleChangeRooms} sx={styles.feats}>
                                <Typography sx={styles.text} >Rooms &#38; Cottages</Typography>
                            </Box>
                        </Paper>
                        <Paper sx={styles.muiPaper}>
                            <Box component='img' src={Pool} sx={styles.paperImage} />
                            <Box onClick={handleChangePools} sx={styles.feats}>
                                <Typography sx={styles.text} >Pool</Typography>
                            </Box>
                        </Paper>
                        <Paper sx={styles.muiPaper}>
                            <Box component='img' src={Events2} sx={styles.paperImage} />
                            <Box onClick={handleChangeEvents} sx={styles.feats}>
                                <Typography sx={styles.text} >Events</Typography>
                            </Box>
                        </Paper>
                    </Stack>
                </ Box >
            </Box>
            <Collapse in={checkedRooms}>
                <RoomsGallery />
            </Collapse>
            <Collapse in={checkedPools} >
                <PoolsGallery />
            </Collapse>
            <Collapse in={checkedEvents} >
                <EventsGallery />
            </Collapse>
        </Box >
    )
}
