import React from "react";
import { Box, Divider, Typography } from "@mui/material";

function IntroTwo() {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
        margin: "auto",
      }}
    >
      <Typography
        color='primary'
        sx={{
          fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
          fontWeight: "bold",
        }}
      >
        OUR FEATURES AND AMENITIES <br />
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#591934",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
        }}
      />
      <Typography
        sx={{ fontSize: { xl: 20, lg: 23, md: 18, xs: 16 }, padding: 5, fontWeight: "bold" }}
      >
        Fresh overflowing water
        <br />
        Infinity pool with jacuzzi
        <br />
        Clean and cozy air-conditioned room
        <br />
        Free use of Grill
        <br />
        Fast reliable WiFi
        <br />
        Badminton and Volleyball Area
        <br />
        Spacious and well-maintained cottages
        <br />
        Superior suites to Family rooms
        <br />
        24 hour reception and room service
        <br />
        Kitchen area for caterers
        <br />
        Full onsite washroom facilities
        <br />
        Laundry facilities
        <br />
      </Typography>
    </Box>
  );
}

export default IntroTwo;
