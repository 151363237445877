import React from 'react'
import { Box } from '@mui/material'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import Map from './ContctsComp/Map'
import Intro from './ContctsComp/Intro'
import Message from './ContctsComp/Message'

export default function ContactIndex() {
  return (
    <Box>
      <Nav />
      <Intro />
      <Map />
      <Message />
      <Footer />
    </Box>
  )
}
