import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Celeb, Celeb1, Celeb2, Celeb3, Celeb4, Celeb5, Celeb6 } from "../../../utils/assests/images/image";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//import { useNavigate } from 'react-router-dom';
import QuotationRequestButton from "../../../userComp/QuotationRequestButton";

const styles = {
  sliderimg: {
    width: { xl: '50%', lg: '50%', md: '80%', sm: '80%', xs: '100%' },
    height: { xl: '50%', lg: '50%', md: '20%', sm: '20%', xs: '20%' },
    objectFit: 'cover',
    border: 2
  }
}
function Celebrate() {
  //const navigate = useNavigate();
  return (
    <Box
      sx={{
        textAlign: "center",
        margin: 2,
      }}
    >
      <AliceCarousel autoPlay autoPlayInterval="3000" disableButtonsControls disableDotsControls infinite={true} animationType='fadeout' animationDuration='800'>
        <Box component='img' src={Celeb} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Celeb1} sx={styles.sliderimg} alt="Phase2" />
        <Box component='img' src={Celeb2} sx={styles.sliderimg} alt="Function Hall" />
        <Box component='img' src={Celeb3} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Celeb4} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Celeb5} sx={styles.sliderimg} alt="Phase1" />
        <Box component='img' src={Celeb6} sx={styles.sliderimg} alt="Phase1" />
      </AliceCarousel>
      <Typography
        color='primary'
        sx={{
          fontSize: { xl: 40, lg: 45, md: 40, xs: 30 },
          fontWeight: "bold",
        }}
      >
        CELEBRATE YOUR ONCE IN A LIFETIME FAIRY TALE !
        <br />
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#591934",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
        }}
      />
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        Turn fiction to reality at Mikaella’s Resort and Events Place, we turn
        your celebration into a memorable moment. We will create every detail of
        your celebration from personalized service to creating an magical moment
        to remember.
      </Typography>
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        We will make sure to provide elegance and uniqueness to your dream
        celebration. You will definitely capture moments of once in a lifetime
        fairy tale.
      </Typography>
      <QuotationRequestButton event={'- Celebration'}/>
    </Box>
  );
}

export default Celebrate;
