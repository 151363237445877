import React, { useEffect, useState } from "react";
import { Grid, ImageListItem, Box, Typography, CircularProgress, ImageList, TextField, Backdrop, Modal, Fade } from "@mui/material";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import { collection, getDocs, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/backend/firebase";

const styles = {
  paperImage: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    "&:hover": {
      opacity: 1,
    },
  },
  muiPaper: {
    width: { xs: 300, lg: 250, md: 350, sm: 400 },
    height: { xs: 300, lg: 250, md: 350, sm: 400 },
    textAlign: "center",
    padding: 1,
    "&:hover": {
      backgroundColor: "#fff",
      opacity: 0.7,
    },
  },
  title: {
    fontSize: { xl: 28, lg: 28, md: 24, sm: 22, xs: 20 },
    margin: '30px 50px 0px 50px',
  },
  dropdown: {
    fontSize: 14
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: { xl: '50%', lg: '50%', md: '50%', sm: '90%', xs: '90%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};
const currencies = [
  {
    value: "desc",
    label: 'Most Recent',
  },
  {
    value: "asc",
    label: 'Oldest',
  },
];
export default function GalleryIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [sortBy, setsortBy] = useState();
  const handleChange = (event) => {
    setsortBy(event.target.value);
  };
  //
  useEffect(() => {
    const collectionRef = collection(db, "sharedGallery");
    const q = query(collectionRef, orderBy("date", sortBy));
    const unsub = onSnapshot(q, (snapshot) =>
      setGallery(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))),
    );
    return unsub;
  }, [sortBy]);

  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "sharedGallery"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);

      });
      setTimeout(() => {
        setGallery(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);
  const [current, setCurrent] = useState({})
  const [open, setOpen] = useState(false);
  const handleOpen = (data) => {
    setCurrent(data)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Nav />
      <Grid
        container
        spacing={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: 'auto',
          width: { xl: '80vw', lg: '80vw', md: '80vw', sm: '90vw', xs: '100vw' },
        }}
      >
        <Typography sx={styles.title}>Shared Gallery</Typography>
        <TextField
          id="filled-select-currency-native"
          select
          label="Sort By"
          value={sortBy}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          inputProps={{ style: { fontSize: { xl: 20, lg: 20, md: 18, sm: 16, xs: 12 } } }}
          size='small'
          variant="outlined"
          sx={{ margin: '10px 0px 0px 50px' }}
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        <Box
          sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          {isLoading ? ( <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <CircularProgress size='30vw' thickness={3} />
        </Box>)
            : (
              <Box sx={{ overFlowY: 'scroll', minHeight: { xl: "80vh", xs: '50vh' } }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                  {gallery.map((gal) => (
                    <ImageListItem key={gal.id}>
                      <img
                        src={`${gal.imageUrl}?w=248&fit=crop&auto=format`}
                        srcSet={`${gal.imageUrl}?w=248&fit=crop&auto=format&dpr=22x`}
                        alt={gal.title}
                        loading="lazy"
                        onClick={() => handleOpen(gal)}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            )}
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={styles.modalBox}>
              <Typography sx={{ fontSize: { xl: 28, lg: 28, md: 24, sm: 22, xs: 20 }, }}>
                {current.title}
              </Typography>
              <Typography sx={{ fontSize: { xl: 16, lg: 16, md: 16, sm: 14, xs: 14 }, }} color='GrayText'>
                {current.date}
              </Typography>
              <img
                src={current.imageUrl}
                srcSet={current.imageUrl}
                width='100%'
                alt={current.title}
                loading="lazy"
              />
            </Box>
          </Fade>
        </Modal>
      </Grid>
      <Footer />
    </Box>
  );
}
