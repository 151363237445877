import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";


export default function Payment({ paymentData, paymentType, packageName, price }) {
  const [payload, setPayload] = useState({
    newPrice: ""
  })

  useEffect(() => {
    if (paymentType === 'Half Payment') {
      setPayload({ ...payload, newPrice: price / 2 })
    } else if (paymentType === 'Full Payment') {
      setPayload({ ...payload, newPrice: price })
    } else {
      setPayload({ ...payload, newPrice: '' })
    }
  }, [paymentType])

  return (

    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: '100%',
        overflowY: 'scroll',
        maxHeight: '50vh',
      }}
    >
      <Typography variant='h5' color='initial' sx={{ margin: "10px", fontSize: { xs: 15, md: 24 } }}>
        Review your payment and press "Continue" to proceed
      </Typography>


      <Box
        sx={{
          backgroundColor: "#591934",
          width: { xs: "80%", md: "90%" },
          height: "120px",
          borderRadius: { xl: 10, lg: 10, md: 10, sm: 10, xs: 0 },
          display: "flex",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant='h6' color='#FFF'>
            Total Cost
          </Typography>
          <Typography color='#FFF' sx={{ fontSize: 26 }}>
            PHP {payload.newPrice}
          </Typography>
          <br />
          <Typography color='#FFF' sx={{ fontSize: 18 }}>
            {paymentType} - {packageName}
          </Typography>
        </Box>
        <Box component='span' sx={{ flexGrow: 1 }} />
        <img src={paymentData.data.MoPLogo} width='100px' alt='GCASH' />
      </Box>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          marginTop: 1,
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        <Typography sx={{ fontSize: { xs: 16, md: 20 } }}>
          Please complete your payment :
          Scan QR code
        </Typography>
        <Box component='span' sx={{ flexGrow: 1 }} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >

        <Box
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 0px 20px 0px",
          }}
        >
          <img src={paymentData.data.QR} width='250px' alt='GCASH' />
        </Box>
      </Box>

    </Box>

  )
}
