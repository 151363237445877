import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography, Portal, IconButton, CircularProgress } from "@mui/material";

import { BsSnow2, BsWifi } from "react-icons/bs";
import { GiFireBowl, GiShuttlecock, GiVolleyballBall } from "react-icons/gi"
import { GoPrimitiveDot } from "react-icons/go"
import PhasePreview from "./PhasePreview";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { collection, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../../utils/backend/firebase";
import { Link } from "react-router-dom";


const styles = {
  paperImage: {
    margin: 3,
    maxWidth: { xl: '30vw', lg: '30vw', md: '50vw', sm: '80vw', xs: '90vw' },
    maxHeigth: { xl: '30vw', lg: '30vw', md: '50vw', sm: '80vw', xs: '90vw' },
    width: "100%",
    height: "100%",
    "&:hover": {
      opacity: 0.7,
      bgColor: "#000"
    },
  },

};

function Phase() {
  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [phase, setPhase] = useState([]);
  const [roomContent, setRoomContent] = useState({});

  const handleShowMore = (data) => {
    setRoomContent(data)
    setShow(true);
  };
  const handleClick = () => {
    setShow(!show);
  };
  useEffect(() => {
    async function fetchData() {
      let dataUser = [];
      const querySnapshot = await getDocs(collection(db, "Packages"));
      querySnapshot.forEach((doc) => {
        dataUser.push({ id: doc.id, ...doc.data() });
        setIsLoading(true);

      });
      setTimeout(() => {
        setPhase(dataUser);
        setIsLoading(false);
      }, 500);
    }
    fetchData();
  }, []);

  return (
    <Box sx={{ padding: '0px 30px' }}>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#000",
          width: "100%",
        }}
      />
      {isLoading ? ( <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <CircularProgress size='30vw' thickness={3} />
        </Box>)
        : (
          <Box ref={container}>
            {show ? (
              <Portal container={container.current}>
                <IconButton variant="contained" color="primary" onClick={handleClick}><ArrowBackIcon sx={{ fontSize: 35 }} /></IconButton>
                <Typography variant="h5" sx={{fontWeight: "bold"}} color="error">Attention: </Typography>
                <Typography variant="h5" color="error">Before you book, please ask the availability of <br/>the Date and Phase/Event that you want to book to avoid inconveniences for you.</Typography>
                <Link to="/contact" ><Typography variant="h6"> Click here to contact us.</Typography></Link>
                <PhasePreview data={roomContent} />
              </Portal>
            ) :
              <Box>
                {
                  phase.map((gal) => (
                    <Box key={gal.id} sx={{ display: 'flex', flexDirection: { xl: "row", lg: 'row', md: 'column', sm: 'column', xs: 'column' }, alignItems: 'center' }}>
                      <Box component='img' src={gal.imageUrl} alt="image" sx={styles.paperImage} />
                      <Box sx={{ display: 'flex', flexDirection: { xl: "row", lg: 'row', md: 'row', sm: 'row', xs: 'column' } }}>
                        <Box sx={{ width: { xl: "50vw", lg: '50vw', md: '50vw', sm: '50vw', xs: '100vw' }, display: 'flex', flexDirection: "column" }}>
                          <Box sx={{ flexDirection: "row", padding: 3 }}>
                            <Typography sx={{ fontSize: { xl: 56, lg: 48, md: 40, xs: 32 } }}>{gal.PackageName}</Typography>
                            <Typography sx={{ fontSize: { xl: 28, lg: 24, md: 20, xs: 16 } }}>
                              {gal.Caption}
                            </Typography>
                            <GoPrimitiveDot color="black" style={{ marginTop: '30px', marginLeft: '25px', verticalAlign: 'text-bottom' }} />Beds:
                            {gal.Beds}
                            <Divider color="black" fontWeight="bold" sx={{ marginTop: 3, width: "100%" }} />
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: { sm: 'unset', xs: 'center' }, padding: '0px 30px 30px 30px' }}>
                            <BsSnow2 style={{ fontSize: 30, display: gal.AC ? 'block' : 'none' }} />
                            <BsWifi style={{ fontSize: 30, display: gal.WiFi ? 'block' : 'none' }} />
                            <GiFireBowl style={{ fontSize: 30, display: gal.Grill ? 'block' : 'none' }} />
                            <GiShuttlecock style={{ fontSize: 30, display: gal.Badminton ? 'block' : 'none' }} />
                            <GiVolleyballBall style={{ fontSize: 30, display: gal.Volleyball ? 'block' : 'none' }} />
                          </Box>
                        </Box>

                        <Box sx={{ width: { xl: "15vw", lg: '15vw', md: '50vw', sm: '50vw', xs: '100vw' }, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                          <Typography sx={{ color: "blue", fontSize: { xl: 24, lg: 24, md: 20, sm: 20, xs: 18 } }}>
                            From
                          </Typography>
                          <Typography sx={{ color: "blue", fontSize: { xl: 24, lg: 24, md: 20, sm: 20, xs: 18 } }}>
                            PHP {gal.Price}
                          </Typography>
                          <Typography sx={{ color: "blue", fontSize: 14 }}>
                            per night
                          </Typography>
                          <Button variant="contained" color="primary" onClick={() => handleShowMore(gal)} sx={{ fontSize: { xl: 24, lg: 24, md: 20, sm: 20, xs: 18 }, fontWeight: 500, textTransform: 'none' }}>More Info</Button>
                        </Box>
                      </Box>
                      <Divider
                        orientation='horizontal'
                        sx={{
                          display: { xl: 'none', lg: 'none', md: 'block', sm: 'block', xs: 'block' },
                          border: 2,
                          color: "#000",
                          width: "100%",
                          margin: '30px 0px',
                        }}
                      />
                    </Box>
                  ))}
                <Divider
                  orientation='horizontal'
                  sx={{
                    display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' },
                    border: 2,
                    color: "#000",
                    width: "100%",
                    margin: '30px 0px',
                  }}
                />
              </Box>
            }
          </Box>)}
    </Box >
  );
}

export default Phase;
