import {
  Box,
  Typography,
  TextField,
  Fab,
  Popper,
  Fade,
  Card,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { db } from "../../../utils/backend/firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import moment from "moment";
import { Star } from "@mui/icons-material";
import Rating from '@mui/material/Rating';
import { UserAuth } from "../../../utils/backend/context/AuthContext";
import LoginAndReg from "../../../userComp/LoginAndReg";

const fabStyle = {
  position: "fixed",
  bottom: 20,
  right: 20,
  transform: "scaleX(-1)",
};
const fabs = {
  color: "secondary",
  sx: fabStyle,
  icon: <Star />,
  label: "Feedback",
};
export default function Ratings() {

  const { user } = UserAuth();
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    comments: "",
    rate_date: moment(new Date()).format("LL").toString(),
    rate: 0,
    totalRate: 0
  });
  const usersCollectionRef = collection(db, "starrating");
  const createRate = async () => {
    await addDoc(usersCollectionRef, {
      name: payload.name,
      email: payload.email,
      comments: payload.comments,
      rate_date: payload.rate_date,
      rate: Number(payload.rate),
    });
  };

  const handleChange = (prop) => (event) => {
    setPayload({ ...payload, [prop]: event.target.value });
  };
  const [open, setOpen] = useState(false);
  const elRef = useRef(null);

  const handleClick = () => {
    setOpen((previousOpen) => !previousOpen);
  };
  useEffect(() => { 
    setTimeout(() => {
      setOpen((previousOpen) => !previousOpen);
    }, 15000);
  }, []);

  useEffect(() => {
    async function fetchData() {
      let allRating = [];
      const querySnapshot = await getDocs(collection(db, "starrating"));
      querySnapshot.forEach((doc) => {
        allRating.push(doc.get("rate"));
      })
      const sum = (allRating.reduce((a, b) => a + b, 0) / allRating.length);
      setTimeout(() => {
        setPayload({ ...payload, totalRate: sum });
      }, 5000)
    }
    fetchData();
  }, []);

  const canBeOpen = open && Boolean(elRef.current);
  const id = canBeOpen ? "transition-popper" : undefined;
  return (
    <Box
      sx={{
        backgroundColor: "#591934",
        textAlign: "center",
        margin: "auto",
        padding: 5,
      }}
    >
      <Typography variant='h5' color='white'>
        RATING <br /> <br />
      </Typography>
      <Rating
        readOnly
        value={payload.totalRate}
        max={5}
        sx={{
          fontSize: {
            lg: "4rem",
            xs: "3rem",
          }
        }}
        color='yellow'
      />

      <Fab
        sx={fabStyle}
        aria-label={fabs.label}
        color={fabs.color}
        ref={elRef}
        onClick={handleClick}
      >
        <Star fontSize='large' />
      </Fab>
      <Popper
        id={id}
        open={open}
        anchorEl={elRef.current}
        placement='top-end'
        transition
        sx={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              component={Card}
              elevation={10}
              sx={{
                border: 2,
                p: 2,
                bgcolor: "background.paper",
                width: { sm: 450, xs: 300 },
                borderRadius: 5,
                marginRight: { sm: 3, xs: 0 },
                marginBottom: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {user ? (<>
                <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                  Give Feedback
                </Typography>
                <TextField
                  label='Name'
                  variant='outlined'
                  fullWidth
                  sx={{ marginY: 1 }}
                  value={payload.name}
                  onChange={handleChange("name")}
                />
                <TextField
                  label='Email'
                  variant='outlined'
                  fullWidth
                  sx={{ marginY: 1 }}
                  value={payload.email}
                  onChange={handleChange("email")}
                />
                <TextField
                  label='Comments'
                  multiline
                  rows={5}
                  fullWidth
                  sx={{ marginY: 1 }}
                  variant='outlined'
                  value={payload.comments}
                  onChange={handleChange("comments")}
                />
                <Rating
                  value={payload.rate}
                  color='yellow'
                  onChange={handleChange("rate")}
                  max={5}
                  size="large"
                />
                <Box
                  sx={{
                    display: "flex",
                    flesDirection: "row",
                    paddingLeft: { sm: "50%", xs: "20%" },
                  }}
                >
                  <Button
                    variant='contained'
                    color='error'
                    sx={{ margin: 1 }}
                    onClick={handleClick}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    color='success'
                    sx={{ margin: 1 }}
                    onClick={createRate}
                  >
                    Confirm
                  </Button>
                </Box>
              </>) : (<LoginAndReg />)}
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
