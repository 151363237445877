import { Box, Typography } from "@mui/material";
import React from "react";
import { sun } from "../../../utils/assests/images/image";
function Div() {
  return (
    <Box
      component='div'
      sx={{
        backgroundColor: "#591934",
        textAlign: "center",
        margin: "auto",

        padding: 3,
      }}
    >
      <Typography
        color='secondary'
        sx={{ fontSize: { xl: 56, lg: 48, md: 40, xs: 32 } }}
      >
        RELAX, UNWIND &#38; CELEBRATE <br />
      </Typography>
      <Box
        component='img'
        src={sun}
        alt='line-drawing-sun'
        sx={{
          width: { xl: 600, lg: 500, md: 400, xs: 300 },
          height: { xl: 220, lg: 200, md: 180, xs: 150 },
        }}
      />
      <Typography
        color='white'
        sx={{ fontSize: { xl: 28, lg: 24, md: 20, xs: 16 } }}
      >
            Mikaella’s Resort and Events Place is a family owned and family operated
        resort located in Donacion, Angat, Bulacan. It is located inside WCL Gas
        Station. We are a family friendly resort. It has 10 fully serviced
        Superior Rooms and Family Rooms. It also offers a fully air-conditioned
        Function Hall with a max capacity of 200 guests which is perfect for
        parties and special events. <br/> <br/>Mikaella’s Resort also boasts its fresh
        overflowing water and infinity pool with jacuzzi . We have everything
        you and your family need for a vacation of a lifetime. Experience the
        water for excitement and relax in a tranquil bliss with your special
        love one or with the whole family.
      </Typography>
    </Box>
  );
}

export default Div;
