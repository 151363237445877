import React, { useEffect, useState } from 'react'
import { Box, Card, Typography, Collapse, CircularProgress } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BookingDetails from './BookingDetails';
import { auth, db } from '../../../utils/backend/firebase';
import { useNavigate } from 'react-router-dom';

export default function MobileView() {
  const [user, setUser] = useState([]);
  const [currentUser, setCurrentUser] = useState('');
  const [status, setStatus] = useState("");
  const [AllBookings, setAllBookings] = useState([])
  const [expanded, setExpanded] = useState({ AllBookings });
  const [opened, setOpened] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleChange = (id) => {
    setOpened(id);
    setExpanded({
      ...expanded,
      [id]: !expanded[id]
    });
  }

  useEffect(() => {
    db.collection('AllBooks').onSnapshot(snapshot => {
      setUser(snapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    })
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    let books = []
    user.map(({ id, data }) => {
      if ((currentUser === data.bookID) && (data.status === 'Active')) {
        books.push({ id, data })
        setStatus(data.status)
      }
    })
    setAllBookings(books)
  }, [user])

  console.log(AllBookings);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //user has logged in
        setCurrentUser(authUser.uid)
      } else if (!authUser) {
        //user is logged out
        alert("Log in!")
      }
    })

    return () => {
      // perform clean up actions
      unsubscribe();
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <CircularProgress size='30vw' thickness={3} />
        </Box>
      ) :
        (<>
          {!AllBookings.length <= 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minHeight: '50vh', flexDirection: 'column', padding: 2 }}>
              {AllBookings.map(({ id, data }) => (
                data.status === 'Active' ? (
                  <Card key={id} onClick={() => handleChange(id)} elevation={5} sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '90%', minHeight: 100, padding: 1, backgroundColor: '#591934', marginTop: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '90%', height: 100, padding: 1, backgroundColor: '#591934', }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h6' sx={{ color: '#fff' }}>{data.PackageName}</Typography>
                        <Typography sx={{ fontSize: 18, color: '#fff' }}>{data.ArriveDate}</Typography>
                        <Typography sx={{ fontSize: 18, color: '#fff' }}>{data.status}</Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />
                      {expanded[id] ? <KeyboardArrowDownIcon fontSize='large' /> : <ChevronRightIcon fontSize='large' />}
                    </Box>
                    <Collapse orientation="vertical" in={expanded[id]}>
                      <BookingDetails
                        Arrives={data.ArriveDate}
                        Departs={data.DepartDate}
                        Package={data.PackageName}
                        Types={data.Type}
                        Methods={data.Method}
                        Totals={data.TotalPerson}
                        Price={data.Price}
                        Customer={data.CustomerName}
                        Email={data.Email}
                        Status={data.status}
                        OpenID={opened}
                      />
                    </Collapse>
                  </Card>) : (<Box key={id}></Box>)
              ))}
            </Box>) : (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', minHeight: '50vh', flexDirection: 'column', padding: 2 }}>
              <Typography variant="h3" color="initial">
                No bookings yet.
              </Typography>
            </Box>
          )
          }
        </>)}
    </>
  )
}
