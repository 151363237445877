import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import { Pool, Pool2, Road, BG, View, Pool3 } from "../../../../utils/assests/images/image";
import Marquee from "react-fast-marquee";
const styles = {
    sliderimg: {
        width: '250px',
        height: '250px',
        margin: 5,
        objectFit: 'cover'
    },
    text: {
        fontSize: { xl: 26, lg: 26, md: 20, sm: 18, },
        color: '#000',
        textAlign: 'center'
    },
    label: {
        fontSize: { xl: 22, lg: 22, md: 18, sm: 16, },
        color: '#000',
        textAlign: 'center'
    },
}

export default function PoolsGallery() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' } }}>
            <Box sx={{ display: 'flex', width: { xl: '30%', lg: '30%', md: '50%', sm: '100%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography sx={styles.text} >Pools</Typography>
                <Typography sx={styles.label}>Our fresh overflowing water and infinity pool with jacuzzi will give you chills and relaxation.</Typography>
            </Box>
            <Box sx={{ display: 'flex', width: { xl: '70%', lg: '70%', md: '50%', sm: '100%', xs: '100%' }, zIndex: -3 }}>
                <Marquee

                    speed={50}
                    gradientWidth='10%'
                >
                    <img src={Pool} style={styles.sliderimg} alt='Pool1'/>
                    <img src={Road} style={styles.sliderimg} alt='Pool2'/>
                    <img src={Pool2} style={styles.sliderimg} alt='Pool3'/>
                    <img src={View} style={styles.sliderimg} alt='Pool4'/>
                    <img src={Pool3} style={styles.sliderimg} alt='Pool5'/>
                    <img src={BG} style={styles.sliderimg} alt='Pool6'/>
                </Marquee>
            </Box>

        </Box>

    )
}
