import { Grid, Card, Box, Typography, Divider, Fade } from "@mui/material";
import React from "react";
import {
  Pool2,
  Cot,
  Events2,
  Road,
  Pool,
  View,
  Cater,
  Fhall,
  Room,
  Owner,
} from "../../../utils/assests/images/image";

const styles = {
  paperImage: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    "&:hover": {
      opacity: 1,
    },
  },
  muiPaper: {
    width: { xs: 300, sm: 250, md: 350, lg: 400 },
    height: { xs: 300, sm: 250, md: 350, lg: 400 },
    textAlign: "center",
    padding: 1,
    "&:hover": {
      backgroundColor: "#fff",
      opacity: 0.7,
    },
  },
};

export default function ResortPreview() {
  return (
    <Box>
      <Grid
        container
        spacing={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: { lg: 10, sm: 5 },
        }}
      >
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Fhall} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Room} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Cot} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Pool2} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Pool} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={View} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Road} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Events2} sx={styles.paperImage} />
          </Card>
        </Grid>
        <Grid item>
          <Card sx={styles.muiPaper} elevation={5}>
            <Box component='img' src={Cater} sx={styles.paperImage} />
          </Card>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: { md: 30, xs: 5 },
          paddingRight: { md: 30, xs: 5 },
          textAlign: "center",
        }}
      >
        <Typography
          color='primary'
          sx={{
            fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
            fontWeight: "bold",
          }}
        >
          MEET THE OWNER <br />
        </Typography>
        <Divider
          orientation='horizontal'
          sx={{
            border: 2,
            color: "#591934",
            width: '50%',
            justifySelf: "center",
            margin: "auto",
            borderRadius: 10
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingLeft: { md: 30, xs: 5 },
          paddingRight: { md: 30, xs: 5 },
          textAlign: "center",
        }}
      >
        <Fade
          in={true}
          enter={true}
          exit={true}
          timeout={{
            appear: 1500,
            enter: 3000,
            exit: 5000,
          }}
        >
          <Box
            component='img'
            src={Owner}
            sx={{
              flexDirection: 'col',
              borderRadius: "80%",
              width: { xl: '30%', lg: '30%', md: '30%', sm: '50%', xs: '50%' },
              height: { xl: '30%', lg: '30%', md: '30%', sm: '50%', xs: '50%' },
              margin: 1,
            }}
          />
        </Fade>
        <Typography
          color='primary'
          sx={{
            fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
            fontWeight: "bold",
          }}
        >“The heart of hospitality, for me, is the ability to focus completely and totally on one person, even if only for a matter of seconds, yet long enough that you've got a clear connection, a channel between the two of you.”
        </Typography>
      </Box>
    </Box >
  );
}
