import React, { useEffect, useState } from 'react'
import { Box, Button, Modal, Typography, Stepper, Step, StepLabel } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import PaymentDetails from './PaymentDetails'
import PaymentConfirmation from './PaymentConfirmation';
import Payment from './Payment';
import { db } from '../../../utils/backend/firebase';
const style = {
    modalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xl: '50vw', lg: '50vw', md: '80vw', sm: '80vw', xs: '90vw' },
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 10,
        overFlow: 'hidden'
    },
    text: {
        color: '#591934'
    }
};

const steps = ['Reservation Details', 'Reservation Payment', 'Payment Proof'];

export default function PaymentProcedure({ event, paymentMethod, paymentType, paymentData, userInfo, packageName, checkIn, time, guest, price, reference }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); navigate(-1) };
    const handleFinish = () => { navigate('/view-booking') };
    const [activeStep, setActiveStep] = React.useState(0);

    const [payment, setPayment] = useState([]);
    useEffect(() => {
        db.collection('PaymentMethods').onSnapshot(snapshot => {
            setPayment(snapshot.docs.map(doc => ({
                data: doc.data().No,
                id: doc.id
            })))
        })
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    function getStepContent(activeStep) {
        switch (activeStep) {
            case 0:
                return <PaymentDetails
                    paymentMethod={paymentMethod}
                    paymentType={paymentType}
                    userInfo={userInfo}
                    packageName={packageName}
                    checkIn={checkIn}
                    time={time}
                    guest={guest}
                    price={price} />
            case 1:
                return <Payment paymentData={paymentData} paymentType={paymentType} packageName={packageName} price={price} />
            case 2:
                return <PaymentConfirmation
                    paymentMethod={paymentMethod}
                    paymentType={paymentType}
                    userInfo={userInfo}
                    packageName={packageName}
                    checkIn={checkIn}
                    time={time}
                    guest={guest}
                    price={price}
                    event={event}
                    reference={reference} />
            default:
                setOpen(false)
                break;
        }
    }
    return (
        < Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
                sx={{
                    width: '50vw',
                    height: 80,
                    border: 1,
                    borderColor: "#750550",
                    fontWeight: 700,
                    fontSize: { lg: 24, sm: 18, xs: 15 },
                    margin: 2,
                    bgcolor: '#fff',
                    alignSelf: 'center',
                    '&:hover': {
                        backgroundColor: '#591934',
                        color: '#FFFFFF'
                    }
                }}
                onClick={handleOpen}
            >
                Pay Now
            </Button>
            <Modal open={open}>
                <Box sx={style.modalBox}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Box sx={{ backgroundColor: '#591934', width: '100%', borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                            <Typography variant="h4" color="#FFF" sx={{ margin: 2 }}>{steps[activeStep]}</Typography>
                        </Box>
                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button onClick={activeStep === 0 ? handleClose : handleBack} variant='contained' color='error' sx={{ margin: 1, width: { xs: 150, md: 250 }, fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 }, }}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                            <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} variant='contained' color='secondary' sx={{ margin: 1, width: { xs: 150, md: 250 }, fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 }, }}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </Box >
    )
}
