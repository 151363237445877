import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  useMediaQuery,
  Avatar,
  MenuItem,
  Menu,
  IconButton,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { logo } from "../utils/assests/images/image";
import MKlogo from "../utils/assests/images/MKlogo.png";
import {
  Link,
  Outlet,
  useNavigate,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventsMenu from "./subMenus/EventsMenu";
import UserMenu from "./subMenus/UserMenu";
import { UserAuth } from "../utils/backend/context/AuthContext";
import { auth } from "../utils/backend/firebase";

function CustomLink({ children, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link
        style={{
          textDecoration: "none",
          color: match ? "#F0D201" : "#FFFFFF",
          fontFamily: "VarelaRound",
          fontSize: match ? 22 : 18,
        }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

export default function Nav() {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.up(600));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  //forAuth Test
 const {user} = UserAuth();
  const SignOut = () => {
    if (window.confirm('Log out?') === true) {
        alert("logged out!");
        auth.signOut();
        navigate('/home');
    } else {
        alert('Cancelled!');
    }
};
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      placement='bottom-start'
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/rooms-and-cottages");
        }}
      >
        Rooms &#38; Cottage
      </MenuItem>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuItem
            onClick={() => {
              navigate("/events/wedding");
              handleMenuClose();
            }}
          >
            Weddings
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/events/celebrate");
              handleMenuClose();
            }}
          >
            Celebrate
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/events/corporate");
              handleMenuClose();
            }}
          >
            Corporate
          </MenuItem>
        </AccordionDetails>
      </Accordion>
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/promos");
        }}
      >
        Promos
      </MenuItem>
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/gallery");
        }}
      >
        Shared Gallery
      </MenuItem>
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/about-us");
        }}
      >
        About Us
      </MenuItem>
      <MenuItem
        onClose={handleMenuClose}
        onClick={() => {
          navigate("/contact");
        }}
      >
        Contact
      </MenuItem>
      <Accordion
        elevation={0}
        sx={{
          borderTop: "1px solid #e2e2e2",
          display: user ? "block" : "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuItem
            onClick={() => {
              navigate("/settings");
              handleMenuClose();
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/view-booking");
              handleMenuClose();
            }}
          >
            View Booking
          </MenuItem>
          <MenuItem 
          onClick={SignOut}>
            Logout
            </MenuItem>
        </AccordionDetails>
      </Accordion>
    </Menu>
  );

  return (
    <Box>
      {!user ? (
        <>
          <Box
            sx={{
              flexGrow: 1,
              boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
            }}
          >
            <AppBar
              position='static'
              enableColorOnDark
              sx={{ minHeight: { lg: "auto", sm: "auto", xs: 80 } }}
            >
              {!matchMD ? (
                <>
                  <Avatar
                    src={MKlogo}
                    sx={{
                      width: 80,
                      height: 80,
                      margin: 1,
                      border: "2px solid #F0D201",
                      zIndex: 1,
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton
                    onClick={handleMenuOpen}
                    aria-controls={isMenuOpen ? "account-menu" : undefined}
                    aria-haspopup='true'
                    aria-expanded={isMenuOpen ? "true" : undefined}
                    color='inherit'
                    sx={{
                      display: {
                        xl: "none",
                        lg: "none",
                        sm: "none",
                        xs: "block",
                      },
                      position: "absolute",
                      right: 10,
                      top: "30px",
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {renderMenu}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        sm: "block",
                        xs: "none",
                      },
                      height: { xl: 180, lg: 150, sm: 120, xs: 80 },
                      padding: 1,
                      backgroundImage: `url(${logo})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100%",
                      backgroundPosition: "center",
                    }}
                  />
                  <Toolbar
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        sm: "flex",
                        xs: "none",
                      },
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: { xl: "60vw", lg: "60vw", sm: "100vw" },
                        alignItems: "center",
                      }}
                    >
                      <CustomLink to='/home'>Home</CustomLink>
                      <CustomLink to='/rooms-and-cottages'>
                        Rooms &#38; Cottage
                      </CustomLink>
                      <EventsMenu />
                      <CustomLink to='/promos'>Promos</CustomLink>
                      <CustomLink to='/gallery'>Shared Gallery</CustomLink>
                      <CustomLink to='/about-us'>About Us</CustomLink>
                      <CustomLink to='/contact'>Contact</CustomLink>
                    </Box>
                  </Toolbar>
                </>
              )}
            </AppBar>
          </Box>
          <Outlet />
        </>
      ) : (
        <>
          <Box
            sx={{
              flexGrow: 1,
              boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
            }}
          >
            <AppBar
              position='static'
              enableColorOnDark
              sx={{ minHeight: { lg: "auto", sm: "auto", xs: 80 } }}
            >
              {!matchMD ? (
                <>
                  <Avatar
                    src={MKlogo}
                    sx={{
                      width: 80,
                      height: 80,
                      margin: 1,
                      border: "2px solid #F0D201",
                      zIndex: 1,
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton
                    onClick={handleMenuOpen}
                    aria-controls={isMenuOpen ? "account-menu" : undefined}
                    aria-haspopup='true'
                    aria-expanded={isMenuOpen ? "true" : undefined}
                    color='inherit'
                    sx={{
                      display: {
                        xl: "none",
                        lg: "none",
                        sm: "none",
                        xs: "block",
                      },
                      position: "absolute",
                      right: 10,
                      top: "30px",
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {renderMenu}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        sm: "block",
                        xs: "none",
                      },
                      height: { xl: 180, lg: 150, sm: 120, xs: 80 },
                      padding: 1,
                      backgroundImage: `url(${logo})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100%",
                      backgroundPosition: "center",
                    }}
                  />
                  <Toolbar
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        sm: "flex",
                        xs: "none",
                      },
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: { xl: "60vw", lg: "60vw", sm: "100vw" },
                        alignItems: "center",
                      }}
                    >
                      <CustomLink to='/home'>Home</CustomLink>
                      <CustomLink to='/rooms-and-cottages'>
                        Rooms &#38; Cottage
                      </CustomLink>
                      <EventsMenu />
                      <CustomLink to='/promos'>Promos</CustomLink>
                      <CustomLink to='/gallery'>Shared Gallery</CustomLink>
                      <CustomLink to='/about-us'>About Us</CustomLink>
                      <CustomLink to='/contact'>Contact</CustomLink>
                      <UserMenu />
                    </Box>
                  </Toolbar>
                </>
              )}
            </AppBar>
          </Box>
          <Outlet />
        </>
      )}
    </Box>
  );
}
