import React from 'react'
import { Box, Tab, useMediaQuery, useTheme } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer'
import Profile from './settings/Profile';
import ChangePass from './settings/ChangePass';

export default function SettingsIndex() {
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.up(900));
    const [value, setValue] = React.useState('userinfo');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box>
            <Nav />
            {matchMD ?
                <Box sx={{ width: { xl: '60%', lg: '70% ', md: "80%", sm: '100%', xs: '100%' }, typography: 'body1', flexDirection: 'row', display: 'flex', margin: 'auto' }}>
                    <TabContext value={value}>
                        <TabList
                            orientation="vertical"
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                display: 'flex',
                                minHeight: { lg: '65vh', xs: '60vh' },
                                height: '100%',
                                width: { xl: '30%', lg: '40% ', md: "40%", xs: '25vw' }
                            }
                            }
                        >
                            <Tab label="Edit User Profile" value='userinfo' />
                            <Tab label="Password Settings" value='passinfo' />
                        </TabList>
                        <TabPanel value='userinfo' sx={{ width: '100%' }}>
                            <Profile />
                        </TabPanel>
                        <TabPanel value='passinfo' sx={{ width: '100%' }}>
                            <ChangePass />
                        </TabPanel>
                    </TabContext>
                </Box > :
                <Box sx={{ width: { xl: '60%', lg: '70% ', md: "80%", sm: '100%', xs: '100%' }, typography: 'body1', flexDirection: 'column', display: 'flex', margin: 'auto' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Edit User Profile" value='userinfo' />
                                <Tab label="Password Settings" value='passinfo' />
                            </TabList>
                        </Box>
                        <TabPanel value='userinfo' >
                            <Profile />
                        </TabPanel>
                        <TabPanel value='passinfo' >
                            <ChangePass />
                        </TabPanel>
                    </TabContext>
                </Box >
            }
            <Footer />
        </Box >
    )
}


