import React from "react";
import { Box, Divider, Typography } from "@mui/material";
function Intro() {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography color='primary' sx={{ fontSize: { xl: 50, lg: 46, md: 38, xs: 30 } }}>
        Rooms &#38; Cottages <br />
      </Typography>

      <Divider
        orientation='horizontal'
        sx={{ border: 2, color: "#F0A500", width: '15%', marginTop: '3%' }}
      />

      <Typography color='#555555' sx={{ fontSize: { xl: 28, lg: 24, sm: 20, xs: 16 } }}>
        Rooms at Mikaella's Resort and Events place are either pool side or beside function hall  and come in different accomodation types from superior double rooms to family rooms.
        <br /><br /> The Nipa and bricked designed Cottages provides a cozy comfortable setting around the pool ideal for families and friends to enjoy <br /><br />
        Note in line with DOT's health and safety guidelines, allowed room capacity will be strickly observed.
      </Typography>

      <Divider
        orientation='horizontal'
        sx={{ border: 2, color: "#000", width: '100%', marginTop: '3%' }}
      />

      <Typography sx={{ textAlign: 'left', margin: 2, fontSize: { xl: 28, lg: 24, sm: 20, xs: 16 } }}>OUR ROOMS AND COTTAGES</Typography>

      <Divider
        orientation='horizontal'
        sx={{ border: 2, color: "#000", width: '100%' }}
      />
    </Box>
  );
}

export default Intro;
