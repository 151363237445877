import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Pool } from "../../../utils/assests/images/image"
function Intro() {
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
        margin: "auto",
      }}
    >
      <Typography
        color='primary'
        sx={{
          fontSize: { xl: 40, lg: 45, md: 40, xs: 30 },
          fontWeight: "bold",
        }}
      >
        ABOUT MIKAELLA'S RESORT &#38; EVENTS PLACE <br />
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#F0A500",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
        }}
      />
      <Typography sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}>
        Mikaella’s Resort and Events Place is a family owned and family operated
        resort located in Donacion, Angat, Bulacan. It is located inside WCL Gas
        Station. We are a family friendly resort. It has 10 fully serviced
        Superior Rooms and Family Rooms. It also offers a fully air-conditioned
        Function Hall with a max capacity of 200 guests which is perfect for
        parties and special events. Mikaella’s Resort also boasts its fresh
        overflowing water and infinity pool with jacuzzi . We have everything
        you and your family need for a vacation of a lifetime. Experience the
        water for excitement and relax in a tranquil bliss with your special
        love one or with the whole family.
      </Typography>
      <Typography sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}>
        We look forward to serve you with your most memorable vacation and special life events!
      </Typography>
      <Box component="img" src={Pool} sx={{ border: 1, width: { xl: '70vw', lg: '70vw', md: '80vw', sm: '90vw', xs: '100%' }, height: { xl: '75vh', lg: '75vh', md: '50vh', sm: '50vh', xs: '50%' }, margin: 'auto' }} />
    </Box>
  );
}

export default Intro;
