import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";

const style = {
  text: {
    color: "#591934",
    fontSize: { xs: 13, md: 24 },
  },
};

export default function PaymentDetails({  paymentMethod, paymentType, userInfo, packageName, checkIn, time, guest, price }) {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        maxHeight: "50vh",
        overflowY: "scroll",
      }}
    >
      <Typography variant='h5' color='initial' sx={{ margin: "10px" }}>
        Please confirm your information is correct.
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{ borderTop: "3px solid #000", width: "98%" }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: "20px 0px 20px 20px",
          }}
        >
          <Typography variant='h6' sx={style.text}>
            Reservation Name Under
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Email
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Mobile Number
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Booking For
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Number of Guest
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Check In
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Check Out
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Payment Type
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Price
          </Typography>
          <Typography variant='h6' sx={style.text}>
            Payment Method
          </Typography>
        </Box>

        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "20px 20px 20px 0px",
          }}
        ><Typography variant='h6' sx={style.text}>
            {userInfo.displayName}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {userInfo.email}
          </Typography><Typography variant='h6' sx={style.text}>
            {userInfo.phoneNumber}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {packageName}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {guest}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {checkIn}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {time}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {paymentType}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            PHP {price}
          </Typography>
          <Typography variant='h6' sx={style.text}>
            {paymentMethod}
          </Typography>
        </Box>

      </Box>
      <Divider
        orientation='horizontal'
        sx={{ borderTop: "3px dashed #000", width: "98%" }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: "20px 0px 20px 20px",
          }}
        >
          <Typography sx={{ fontSize: 26, fontWeight: 600 }}>
            Total Cost:
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            padding: "20px 20px 20px 0px",
          }}
        >
          <Typography sx={{ fontSize: 26 }}>PHP {price}</Typography>
        </Box>
      </Box>
      <Divider
        orientation='horizontal'
        sx={{ borderTop: "3px solid #000", width: "98%", marginBottom: 2 }}
      />
    </Box>
  );
}
