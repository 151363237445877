import React from "react";
import { Box } from "@mui/material";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import Intro from "./AboutComp/Intro";
import Amenities from "./AboutComp/Amenities";
import ResortPreview from "./AboutComp/ResortPreview";

export default function AboutIndex() {
  return (
    <Box>
      <Nav />
      <Intro />
      <Amenities />
      <ResortPreview />
      <Footer />
    </Box>
  );
}
