import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  MobileDatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/lab";
import { Events } from "../../../utils/assests/images/image";
import EditIcon from "@mui/icons-material/Edit";
import { auth, db, storage } from "../../../utils/backend/firebase";
import { Delete, PhotoCamera } from "@mui/icons-material";

import Img from "./Img.jpeg";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const styles = {
  data: {
    fontSize: { xl: 30, lg: 35, md: 30, xs: 20 },
    margin: 1,
  },
  editTextField: {
    margin: 1,
    width: { xl: "100%", lg: "100%", md: "100%", sm: "100%", xs: 300 },
  },
  editButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
};
export default function Profile() {
  const matchXS = useMediaQuery("(min-width:600px)");
  const [click, setClick] = useState(false);
  const [user, setUser] = useState("");
  const [img, setImg] = useState("");
  const [state, setState] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Address: "",
    Birtdate: birthDate,
    gender: "",
  });

  useEffect(() => {
    onAuthStateChanged(auth, (users) => {
      getDoc(doc(db, "ClientsProfile", users.uid)).then((docSnap) => {
        if (docSnap.exists) {
          setUser(docSnap.data());
        }
      });
    });

    if (img) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `avatar/${new Date().getTime()} - ${img.name}`
        );
        try {
          if (user.avatarPath) {
            await deleteObject(ref(storage, user.avatarPath));
          }
          const snap = await uploadBytes(imgRef, img);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await updateDoc(doc(db, "ClientsProfile", auth.currentUser.uid), {
            avatar: url,
            avatarPath: snap.ref.fullPath,
          });

          setImg("");
        } catch (err) {
          console.log(err.message);
        }
      };
      uploadImg();
    }
  }, [img]);
const navigate = useNavigate();
  const UpdateProfile = async () => {
    onAuthStateChanged(auth, (users) => {
    if(user){
      if(!state.Name && !state.Phone && !state.Address && !img){
        window.confirm("Complete All Fields");
      }else {
      updateDoc(doc(db, "ClientsProfile", users.uid), {
        displayName: state.Name,
        phoneNumber: state.Phone,
        address: state.Address,
        photoURL: img,
      });
      navigate('/settings')
      window.confirm("Profile Updated!");
    }
    }else{
        window.confirm("Profile Not Updated!");
    }
    })
};
  const deleteImage = async () => {
    try {
      const confirm = window.confirm("Delete avatar?");
      if (confirm) {
        await deleteObject(ref(storage, user.avatarPath));

        await updateDoc(doc(db, "ClientsProfile", auth.currentUser.uid), {
          avatar: "",
          avatarPath: "",
        });
        //history.replace("/");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDate = (newValue) => {
    setBirthDate(newValue);
  };

  const handleClick = () => {
    setClick(!click);
  };
  const [birthDate, setBirthDate] = useState(new Date("2014-08-18"));

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  return user ? (
    <Box
      Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography variant='h4' sx={{ margin: 1 }}>
        My Profile
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: {
            xl: "flex-start",
            lg: "flex-start",
            md: "flex-start",
            sm: "center",
            xs: "center",
          },
          width: "100%",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box 
        sx={{
          border: "1px dotted #591934",
          width: { xs: "300px", md: "420px" },
          height: "280px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 2,
          paddingBottom: 5
        }}
      >
        <div className="profile_container">
        <div className="img_container">
          <img src={user.avatar || null} style={{
          width: { xs: "300px", md: "420px" },
          height: "280px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 2,
          paddingBottom: 5}}/>
          <div className="overlay">
            <div>
              <label htmlFor="photo">
                <PhotoCamera />
              </label>
              {user.avatar ? <Delete onClick={deleteImage} style={{marginLeft: 2}}/> : null}
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="photo"
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>
          </div>
        </div>
        </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: {
              xl: "flex-start",
              lg: "flex-start",
              md: "flex-start",
              sm: "center",
              xs: "center",
            },
            flexDirection: "column",
            width: "100%",
          }}
        >
          <TextField
            required
            id='outlined-required'
            label='Name'
            autoComplete='off'
            fullWidth
            value={!click ? user.displayName : state.Name}
            onChange={handleChange("Name")}
            disabled={click === false}
            sx={styles.editTextField}
          />
          <TextField
            required
            id='outlined-required'
            label='Email'
            autoComplete='off'
            fullWidth
            disabled
            value={user.email}
            //value={state.Email}
            onChange={handleChange("Email")}
            sx={styles.editTextField}
          />
          <TextField
            required
            id='outlined-required'
            label='Phone Number'
            autoComplete='off'
            fullWidth
            disabled={click === false}
            value={!click ? user.phoneNumber : state.Phone}
            //value={state.Phone}
            onChange={handleChange("Phone")}
            sx={styles.editTextField}
          />
        </Box>
      </Box>
      <TextField
        required
        id='outlined-required'
        label='Address'
        autoComplete='off'
        fullWidth
        disabled={click === false}
        value={!click ? user.address : state.Address}
        //value={state.Address}
        onChange={handleChange("Address")}
        sx={styles.editTextField}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!matchXS ? (
          <MobileDatePicker
            value={user.Birthday}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                color='secondary'
                autoComplete='off'
                sx={styles.editTextField}
                disabled
              />
            )}
          />
        ) : (
          <DesktopDatePicker
            value={user.Birthday}
            //value={birthDate}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                color='secondary'
                autoComplete='off'
                sx={styles.editTextField}
              />
            )}
          />
        )}
      </LocalizationProvider>
      <FormControl
        required
        component='fieldset'
        disabled={click === false}
        sx={{ marginBottom: "50px" }}
      >
        <FormLabel component='legend'>Gender</FormLabel>
        <RadioGroup
          row
          aria-label='gender'
          sx={{ width: { sm: 400, xs: 300 } }}
          value={!click ? user.gender : state.gender}
          //value={state.gender}
          onChange={handleChange('gender')}
        >
          <FormControlLabel
            value='male'
            control={<Radio color='secondary' />}
            label='Male'
          />
          <FormControlLabel
            value='female'
            control={<Radio color='secondary' />}
            label='Female'
            sx={{ marginLeft: { sm: 20, xs: 10 } }}
          />
        </RadioGroup>
      </FormControl>
      {click ? (
        <Button
          onClick={UpdateProfile}
          sx={styles.editButton}
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
        >
          Save
        </Button>
      ) : (
        <Button
        onClick={handleClick}
          sx={styles.editButton}
          variant='contained'
          color='secondary'
          startIcon={<EditIcon />}
        >
          Update
        </Button>
      )}
      
    </Box>
  ) : null;
}
