import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { Corp, Corp1, Corp2 } from "../../../utils/assests/images/image";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//import { useNavigate } from 'react-router-dom';
import QuotationRequestButton from "../../../userComp/QuotationRequestButton";

const styles = {
  sliderimg: {
    width: { xl: '50%', lg: '50%', md: '80%', sm: '80%', xs: '100%' },
    height: { xl: '50%', lg: '50%', md: '20%', sm: '20%', xs: '20%' },
    objectFit: 'cover',
    border: 2
  }
}
function Corporate() {
  // const navigate = useNavigate();
  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 3,
      }}
    >
      <Typography
        color='primary'
        sx={{
          fontSize: { xl: 40, lg: 45, md: 40, xs: 30 },
          fontWeight: "bold",
        }}
      >
        MEETINGS &#38; SEMINARS !
        <br />
      </Typography>
      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#591934",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
          marginBottom: 2,
        }}
      />
      <AliceCarousel
        autoPlay
        autoPlayInterval='3000'
        disableButtonsControls
        disableDotsControls
        infinite={true}
        animationType='fadeout'
        animationDuration='800'
      >
        <Box component='img' src={Corp} sx={styles.sliderimg} alt='Phase1' />
        <Box component='img' src={Corp1} sx={styles.sliderimg} alt='Phase2' />
        <Box
          component='img'
          src={Corp2}
          sx={styles.sliderimg}
          alt='Function Hall'
        />
      </AliceCarousel>

      <Divider
        orientation='horizontal'
        sx={{
          border: 2,
          color: "#591934",
          width: { xl: "15%", lg: "13%", md: "8%", xs: "5%" },
          justifySelf: "center",
          margin: "auto",
          marginTop: 2,
        }}
      />
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        Your meetings and seminars made easy by our event management and food
        and beverage team. We are committed to provide our finest service for
        all your corporate and business functions.
      </Typography>
      <Typography
        sx={{ fontSize: { xl: 30, lg: 35, md: 30, xs: 20 }, padding: { xl: 5, lg: 4, md: 3, xs: 1 } }}
      >
        Not only for meetings, we are also a perfect getaway for your company
        outings and team buildings. We offer the best outdoor experience to
        satisfy your team building objectives.
      </Typography>
      <QuotationRequestButton event={'- Corporate'} />
    </Box>
  );
}

export default Corporate;
