import React, { useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { auth, db } from '../../../utils/backend/firebase';
import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';
const style = {
    text: {
        color: '#fff',
        fontSize: 18
    }
}
export default function BookingDetails({ Arrives, Departs, Package, Types, Methods, Totals, Price, OpenID }) {
    const [user, setUser] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);
    const [payload, setPayload] = useState({
        date: moment(new Date())
            .format("LL")
            .toString(),
    });
    useEffect(() => {
        db.collection('AllBooks').onSnapshot(snapshot => {
            setUser(snapshot.docs.map(doc => ({
                data: doc.data(),
                id: doc.id
            })))
        })
    }, []);
    console.log(OpenID);
    const handleDelete = (OpenID) => {
        user.map(({ id, data }) => {
            if (OpenID === id) {
                if (data.reference === 'Booking') {
                    updateDoc(doc(db, "SummaryBook", data.localID), {
                        status: "Inactive",
                        paymentStatus: 'Refund',
                    })
                } else if (data.reference === 'Events') {
                    updateDoc(doc(db, "EventsDetails", data.localID), {
                        paymentStatus: 'Refund',
                        status: "Inactive",
                    })
                }
                else {
                    console.log('No such data exist!');
                }
                updateDoc(doc(db, "AllBooks", OpenID), {
                    status: "Inactive",
                    paymentStatus: 'Refund',
                })
                db.collection('Notifications').add({
                    CustomerName: data.CustomerName,
                    CustomerEmail: data.Email,
                    Desc: 'Cancel Book!, Need Refund',
                    Date: payload.date,
                    Status: 'Unread',
                    Title: 'New Message!'
                }).catch((error) => {
                    console.log(error);
                });
                window.alert("Canceled Successfully! Refund will sent shortly.")
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        })

    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                //user has logged in
                setCurrentUser(authUser.uid)
            } else if (!authUser) {
                //user is logged out
                console.log('Log in!')
            }
        })

        return () => {
            // perform clean up actions
            unsubscribe();
        }
    }, []);

    return (
        <Box sx={{ position: 'relative' }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 5
            }}>
                <Box sx={{ minWidth: '35vw', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: '20px 0px 20px 20px' }}>
                    <Typography variant="h6" sx={style.text}  >Booking For</Typography>
                    <Typography variant="h6" sx={style.text} >Guest</Typography>
                    <Typography variant="h6" sx={style.text}  >Check In</Typography>
                    <Typography variant="h6" sx={style.text}  >Check Out</Typography>
                    <Typography variant="h6" sx={style.text} >Payment Type</Typography>
                    <Typography variant="h6" sx={style.text}  >Cost</Typography>
                    <Typography variant="h6" sx={style.text} >Payment</Typography>
                </Box>
                <Box sx={{ minWidth: '35vw', display: 'flex', alignItems: 'flex-end', flexDirection: 'column', padding: '20px 20px 20px 0px' }}>
                    <Typography variant="h6" sx={style.text}  >{Package}</Typography>
                    <Typography variant="h6" sx={style.text} >{Totals}</Typography>
                    <Typography variant="h6" sx={style.text}  >{Arrives}</Typography>
                    <Typography variant="h6" sx={style.text}  >{Departs}</Typography>
                    <Typography variant="h6" sx={style.text} >{Types}</Typography>
                    <Typography variant="h6" sx={style.text}  >PHP {Price}</Typography>
                    <Typography variant="h6" sx={style.text} >{Methods}</Typography>
                </Box>
            </Box>
            <Button onClick={() => handleDelete(OpenID)} variant='contained' color='error' sx={{ width: { xs: 200, sm: 300 }, fontSize: { xl: 22, lg: 22, md: 20, sm: 18, xs: 16 }, position: 'absolute', bottom: -35, right: 0 }}>Cancel Booking</Button>
        </Box>
    )
}
