import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';
import { Celeb1, Corp1, Celeb3, Wed3, Celeb5, Corp } from "../../../../utils/assests/images/image";
import Marquee from "react-fast-marquee";
const styles = {
    sliderimg: {
        width: '250px',
        height: '250px',
        margin: 5,
        objectFit: 'cover'
    },
    text: {
        fontSize: { xl: 26, lg: 26, md: 20, sm: 18, },
        color: '#000',
        textAlign: 'center'
    },
    label: {
        fontSize: { xl: 22, lg: 22, md: 18, sm: 16, },
        color: '#000',
        textAlign: 'center'
    },
}

export default function EventsGallery() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' } }}>
            <Box sx={{ display: 'flex', width: { xl: '30%', lg: '30%', md: '50%', sm: '100%', xs: '100%' }, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography sx={styles.text} >Events</Typography>
                <Typography sx={styles.label}>From special events to corporate functions, Mikaella's Resort and Events Place at your service.</Typography>
            </Box>
            <Box sx={{ display: 'flex', width: { xl: '70%', lg: '70%', md: '50%', sm: '100%', xs: '100%' }, zIndex: -3 }}>
                <Marquee
                    speed={50}
                    gradientWidth='10%'>
                    <img src={Celeb1} style={styles.sliderimg} alt='Events1'/>
                    <img src={Celeb3} style={styles.sliderimg} alt='Events2'/>
                    <img src={Corp} style={styles.sliderimg} alt='Events3'/>
                    <img src={Wed3} style={styles.sliderimg} alt='Events4'/>
                    <img src={Corp1} style={styles.sliderimg} alt='Events5'/>
                    <img src={Celeb5} style={styles.sliderimg} alt='Events6'/>
                </Marquee>
            </Box>

        </Box>

    )
}
