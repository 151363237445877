import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../firebase';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  
  const googleSignIn = () => {
    const googleprovider = new GoogleAuthProvider();
    signInWithPopup(auth, googleprovider);
  };
  const facebookSignIn = () => {
    const facebookprovider = new FacebookAuthProvider();
    signInWithPopup(auth, facebookprovider);
  };
  const submit = (email, password) =>  {
    return signInWithEmailAndPassword(auth, email, password)
   }
  const logOut = () => {
      signOut(auth)
  }
  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('User', currentUser)
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{createUser, googleSignIn, logOut, facebookSignIn, user, submit }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};