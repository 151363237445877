import React from 'react'
import { Box } from '@mui/material'
import Nav from '../../components/Nav'
import Cover from './HomeComp/Cover'
import Footer from '../../components/Footer'
import Intro from './HomeComp/Intro'
import Offer from './HomeComp/Offer'
import Ratings from './HomeComp/Ratings'
import Feedback from './HomeComp/Feedback'

export default function HomeIndex() {
  return (
    <Box>
      <Nav />
      <Cover />
      <Intro />
      <Offer />
      <Ratings />
      <Feedback />
      <Footer />
    </Box>
  )
}
