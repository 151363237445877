import { Box, Typography } from '@mui/material'
import React from 'react'
import Footer from './Footer'
import Nav from "./Nav"
import { karl, Emer, Lirra, Angelo } from "./devImg/image"
import { GrInstagram, GrFacebook, GrTwitter } from "react-icons/gr";

function Developers() {
  return (
    <>
      <Nav />
      <Typography sx={{ marginTop: 5, textAlign: "center", fontWeight: 'bold', fontSize: 36 }}> The Developers Behind</Typography>
      <Box sx={{ display: "flex", flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' }, textAlign: "center", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Box sx={{
            border: "1px dotted #591934",
            width: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            height: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 2,
          }} component='img' src={Emer} alt="karl" />
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }} > Ma. Emerlita</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Orbiso</Typography>
          <Typography color="gray" > FullStack Developer</Typography>
          <>
            <Box component='a' href="#" target={"_blank"}>
              <GrFacebook
                style={{
                  color: "#4267B2",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrInstagram
                style={{
                  color: "violet",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrTwitter
                style={{
                  color: "#4D77FF",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>

          </>

        </Box>
        <Box>
          <Box sx={{
            border: "1px dotted #591934",
            width: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            height: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 2,
          }} component='img' src={Angelo} alt="karl" />
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Angelo</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Tadeo</Typography>
          <Typography color="gray" > Front-end Developer</Typography>
          <>
            <Box component='a' href="#" target={"_blank"}>
              <GrFacebook
                style={{
                  color: "#4267B2",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrInstagram
                style={{
                  color: "violet",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrTwitter
                style={{
                  color: "#4D77FF",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>

          </>
        </Box>
        <Box>
          <Box sx={{
            border: "1px dotted #591934",
            width: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            height: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 2,
          }} component='img' src={Lirra} alt="karl" />
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Ma. Lirra</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Santos</Typography>
          <Typography color="gray" > Researcher</Typography>
          <>
            <Box component='a' href="#" target={"_blank"}>
              <GrFacebook
                style={{
                  color: "#4267B2",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrInstagram
                style={{
                  color: "violet",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrTwitter
                style={{
                  color: "#4D77FF",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>

          </>
        </Box>
        <Box>
          <Box sx={{
            border: "1px dotted #591934",
            width: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            height: { xl: '300px', lg: '300px', md: '300px', sm: '250px', xs: '250px' },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 2,
          }} component='img' src={karl} alt="karl" />
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Karl Cristopher</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>Samelo</Typography>
          <Typography color="gray" > FullStack Developer</Typography>
          <>
            <Box component='a' href="#" target={"_blank"}>
              <GrFacebook
                style={{
                  color: "#4267B2",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrInstagram
                style={{
                  color: "violet",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>
            <Box component='a' href="#" target={"_blank"}>
              <GrTwitter
                style={{
                  color: "#4D77FF",
                  fontSize: 25,
                  margin: "10px",
                }}
              />
            </Box>

          </>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default Developers